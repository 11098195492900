import React, { useState } from 'react'

import { Box } from 'src/ui/box'
import { Image } from 'src/ui/image'
import { ProductTabsEnum } from 'src/enums/product-tabs.enum'

import { Tabs } from './tabs'
import { Header } from './header'
import { Description } from './description'
import { TabsContent } from './tabs-content'

interface Props {
  data: Product
}

export const ProductDetails = ({ data }: Props): React.ReactElement<Props> => {
  const [activeTab, setActiveTab] = useState<ProductTabsEnum>(
    ProductTabsEnum.Composition
  )

  const changeActiveTab = (tab: ProductTabsEnum) => setActiveTab(tab)

  return (
    <Box
      px={[5, 5, 5, 7, '128px']}
      gridArea="details"
      width={1}
      height={1}
      display="flex"
      flexDirection={['column', 'column', 'column', 'row']}
    >
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        {data?.photos?.[0] && (
          <Image
            src={data?.photos?.[0]}
            width={1}
            height="auto"
            minWidth="382px"
            maxWidth="677px"
            maxHeight="450px"
          />
        )}
      </Box>

      <Box flex="1" height={1}>
        <Header data={data} />

        <Description data={data} />

        <Tabs activeTab={activeTab} changeActiveTab={changeActiveTab} />

        <TabsContent activeTab={activeTab} data={data} />
      </Box>
    </Box>
  )
}
