import React, { useMemo, ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from 'src/ui/box'
import { Content } from 'src/ui/content'
import { BreadCrumbs } from 'src/ui/bread-crumbs'

import { Common } from 'src/common/common'
import { Proposals } from 'src/main/ui/proposals'

import { routes } from 'src/routes'
import { useGlobalState } from 'src/hooks/use-global-state'
import { ProductTypeEnum } from 'src/enums/product-type.enum'

import { ProductDetails } from './ui/product-details'

export const ProductPage = (): ReactElement => {
  const { state } = useLocation<{ product: Product }>()

  const {
    state: { catalog },
  } = useGlobalState()

  const proposals = useMemo(
    () =>
      catalog
        ? catalog.reduce((acc: Product[], item) => {
            if (item.type.includes(ProductTypeEnum.Proposal)) {
              acc.push(item)
            }

            return acc
          }, [])
        : [],
    [catalog]
  )

  return (
    <Common>
      <Content
        px={0}
        display="grid"
        gridGap={0}
        gridArea="content"
        gridTemplateRows={['82px 1fr 396px']}
        gridTemplateColumns={['1fr']}
        gridTemplateAreas="'breadcrumbs' 'details' 'proposals'"
      >
        <Box px={[5, 5, 5, 7, '128px']} height={1}>
          <BreadCrumbs
            crumbs={[
              { name: 'Главная', path: routes.root },
              { name: 'Каталог', path: routes.catalog },
              { name: state.product.title },
            ]}
          />
        </Box>

        <ProductDetails data={state.product} />

        <Proposals data={proposals} bgColor="white" />
      </Content>
    </Common>
  )
}
