import { routes, catalogRoutes } from 'src/routes'

import { PuffCookie } from 'src/ui/icons/puff-cookie'
import { Shortbread } from 'src/ui/icons/shortbread'
import { ButterCookie } from 'src/ui/icons/butter-cookie'
import { Pastry } from 'src/ui/icons/pastry'
import { Cupcake } from 'src/ui/icons/cupcake'
import { CustardBiscuit } from 'src/ui/icons/custard-biscuit'
import { Pie } from 'src/ui/icons/pie'
import { Bakery } from 'src/ui/icons/bakery'
import { Bucket } from 'src/ui/icons/bucket'

export const catalog: Catalog = [
  {
    title: 'Все категории',
    route: routes.catalog,
    icon: Bucket,
  },
  {
    title: 'Слоёное печенье',
    route: `${routes.catalog}${catalogRoutes.puffCookies}`,
    icon: PuffCookie,
  },
  {
    title: 'Сдобное печенье',
    route: `${routes.catalog}${catalogRoutes.butterCookies}`,
    icon: ButterCookie,
  },
  {
    title: 'Песочное печенье',
    route: `${routes.catalog}${catalogRoutes.shortbread}`,
    icon: Shortbread,
  },
  {
    title: 'Заварное',
    route: `${routes.catalog}${catalogRoutes.custardBiscuits}`,
    icon: CustardBiscuit,
  },
  {
    title: 'Пирожные',
    route: `${routes.catalog}${catalogRoutes.pastries}`,
    icon: Pastry,
  },
  {
    title: 'Кексы',
    route: `${routes.catalog}${catalogRoutes.cupcakes}`,
    icon: Cupcake,
  },
  {
    title: 'Пироги',
    route: `${routes.catalog}${catalogRoutes.pies}`,
    icon: Pie,
  },
  {
    title: 'Хлебобулочные изделия',
    route: `${routes.catalog}${catalogRoutes.bakery}`,
    icon: Bakery,
  },
]
