import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Link } from 'src/ui/link'
import { Box, BoxProps } from 'src/ui/box'

import { appConfig } from 'src/config/app.config'

interface Props {
  text: string
}

const Root = styled(Box)<BoxProps>`
  @media (hover: hover) {
    :hover {
      a {
        color: ${themeGet('colors.primary')};
      }
    }
  }
`

export const PriceButton = ({ text }: Props): ReactElement<Props> => (
  <Root display="flex" alignItems="center">
    <Link href={appConfig.priceUrl} color="black" fontSize={2} textStyle="bold">
      {text}
    </Link>
  </Root>
)
