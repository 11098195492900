import * as React from 'react'

export const Pastry = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M23.895 19.13a.703.703 0 00-.598-.333h-.75v-7.735a.703.703 0 00-.418-.642l-7.234-3.215c.655-.223 1.203-.553 1.636-.986 1.63-1.63 1.308-4.466 1.293-4.586a.703.703 0 00-.613-.613c-.12-.015-2.955-.338-4.586 1.292A3.86 3.86 0 0012 3.14a3.86 3.86 0 00-.625-.828C9.745.683 6.91 1.006 6.79 1.02a.703.703 0 00-.613.613c-.007.06-.09.79.027 1.709l-.949-.422a.703.703 0 00-.782.145A10.254 10.254 0 002.238 6.41a10.254 10.254 0 00-.785 3.943v8.445h-.75a.703.703 0 00-.629 1.017l1.407 2.813c.119.238.362.389.628.389h19.782c.266 0 .51-.15.629-.389l1.406-2.813a.703.703 0 00-.031-.684zM2.859 11.86c.212.068.386.166.588.282.438.25.982.561 1.989.561 1.007 0 1.551-.311 1.989-.561.382-.219.658-.376 1.291-.376.633 0 .909.157 1.29.376.438.25.982.561 1.989.561 1.007 0 1.552-.311 1.99-.561.382-.219.658-.376 1.291-.376.635 0 .911.158 1.294.376.438.25.983.561 1.99.561 1.008 0 1.553-.311 1.99-.561.204-.116.378-.215.59-.282v2.25H2.86v-2.25zm13.592-9.469c-.006.755-.14 2.059-.914 2.833-.36.361-.838.578-1.293.71a2.355 2.355 0 00-1.295-1.467c.155-.48.379-.869.67-1.16.78-.78 2.079-.912 2.832-.916zM12 5.671a.939.939 0 010 1.876.939.939 0 010-1.875zm-1.62-2.364c.292.291.516.68.671 1.16a2.354 2.354 0 00-1.295 1.467c-.456-.132-.932-.349-1.293-.71-.78-.78-.912-2.08-.916-2.833.754.004 2.053.135 2.834.916zm-5.243 1.1l1.556.691c.19.402.442.787.776 1.12.577.578 1.357.971 2.322 1.174A2.347 2.347 0 0012 8.953c.671 0 1.277-.284 1.705-.738l6.122 2.72c-.369.211-.647.362-1.267.362-.634 0-.91-.158-1.293-.376-.438-.25-.983-.562-1.99-.562-1.008 0-1.552.312-1.99.562-.382.218-.659.376-1.292.376s-.909-.158-1.29-.376c-.438-.25-.982-.562-1.989-.562-1.007 0-1.551.312-1.989.562-.382.218-.658.376-1.291.376-.633 0-.91-.158-1.291-.376-.323-.185-.704-.402-1.286-.504v-.065c0-2.217.806-4.31 2.278-5.945zM2.859 15.516h18.282v3.28H2.859v-3.28zm18.597 6.093H2.544l-.703-1.406h20.318l-.703 1.406z"
          fill="#B28B86"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
