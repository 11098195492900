import React from 'react'

import { Text } from 'src/ui/text'

interface Props {
  data: Product
}

export const Description = ({ data }: Props): React.ReactElement<Props> => {
  const { description } = data

  return (
    <Text mb={30} fontSize="18px" lineHeight="24px">
      {description}
    </Text>
  )
}
