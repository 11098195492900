import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { Box } from 'src/ui/box'
import { Card } from 'src/ui/card/card'
import { BreadCrumbs, BreadCrumbsProps } from 'src/ui/bread-crumbs'

import { routes } from 'src/routes'
import { CardTypes } from 'src/enums/card-types.enum'

import { Sorting } from './sorting'

interface Props {
  data: Product[]
  sortingType: SortingTypeEnum
  changeSortingType: (type: SortingTypeEnum) => void
}

const StyledBreadCrumbs = styled(BreadCrumbs)<BreadCrumbsProps>`
  margin-top: 0;
  margin-bottom: 30px;
`

export const Catalog = ({
  data,
  sortingType,
  changeSortingType,
}: Props): ReactElement<Props> => {
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 991px)',
  })

  return (
    <Box width={1} gridArea="catalog">
      <StyledBreadCrumbs
        crumbs={[{ name: 'Главная', path: routes.root }, { name: 'Каталог' }]}
      />

      <Sorting type={sortingType} changeSortingType={changeSortingType} />

      <Box
        width={1}
        display="grid"
        gridArea="catalog"
        justifyContent="center"
        gridGap="2em"
        gridTemplateColumns={[
          'repeat(1, 98%)',
          'repeat(1, 98%)',
          'repeat(1, 98%)',
          'repeat(2, 48%)',
          'repeat(3, 270px)',
        ]}
      >
        {data.map((product, index) => (
          <Card
            key={index}
            type={isTablet ? CardTypes.Horizontal : CardTypes.Vertical}
            product={product}
          />
        ))}
      </Box>
    </Box>
  )
}
