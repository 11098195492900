import React, { ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'

import { Box } from 'src/ui/box'
import { Content, ContentProps } from 'src/ui/content'
import { catalog } from 'src/catalog'

import { CatalogItem } from './catalog-item'

interface Props {
  isOpen: boolean
}

const slideInTop = keyframes`
  0% {
    transform: translateY(-180px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const Root = styled(Content)<ContentProps & { isOpen: boolean }>`
  z-index: 1;
  width: 100%;
  position: absolute;

  animation-name: ${slideInTop};
  animation-duration: 333ms;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
`

export const Catalog = ({ isOpen }: Props): ReactElement<Props> | null => {
  return isOpen ? (
    <Root
      bg="greys.4"
      minWidth="768px"
      maxWidth="1440px"
      top={['140px', '140px', '140px', '140px', '180px']}
      height={['198px', '198px', '198px', '198px', '156px']}
      gridArea="catalog"
      position="absolute"
      borderBottom={1}
      borderBottomColor="greys.0"
      isOpen={isOpen}
    >
      <Box
        width={1}
        display="grid"
        justifyContent="space-between"
        gridTemplateRows={[
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
          'repeat(2, 1fr)',
        ]}
        gridTemplateColumns={[
          'repeat(3, 0.33fr)',
          'repeat(3, 0.33fr)',
          'repeat(3, 0.33fr)',
          'repeat(3, 0.33fr)',
          'repeat(4, 0.25fr)',
        ]}
      >
        {catalog.slice(1).map(({ route, title, icon }, index) => (
          <CatalogItem key={index} to={route} icon={icon} text={title} />
        ))}
      </Box>
    </Root>
  ) : null
}
