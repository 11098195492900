import React from 'react'

import { Box } from 'src/ui/box'

export const ClockIcon = (): React.ReactElement => {
  return (
    <Box as="span">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <path
          d="M18.126 14.269a3.029 3.029 0 00-3.025 3.025 3.029 3.029 0 003.025 3.025 3.028 3.028 0 003.025-3.025 3.029 3.029 0 00-3.025-3.025zm0 4.538a1.514 1.514 0 01-1.512-1.513c0-.834.678-1.513 1.512-1.513s1.513.679 1.513 1.513c0 .834-.679 1.513-1.513 1.513zM7.79 14.269a3.029 3.029 0 00-3.025 3.025A3.029 3.029 0 007.79 20.32a3.029 3.029 0 003.025-3.025A3.029 3.029 0 007.79 14.27zm0 4.538a1.514 1.514 0 01-1.513-1.513c0-.834.679-1.513 1.513-1.513.834 0 1.513.679 1.513 1.513 0 .834-.679 1.513-1.513 1.513zM20.163 5.61a.757.757 0 00-.676-.417h-3.983v1.513h3.517l2.06 4.096 1.351-.68-2.269-4.512z"
          fill="#DA9C8C"
        />
        <path
          d="M15.857 16.563h-5.722v1.513h5.722v-1.513zM5.52 16.563H2.9a.756.756 0 100 1.513H5.52a.756.756 0 100-1.513zM23.841 11.94l-1.488-1.916a.755.755 0 00-.597-.293H16.26V4.437a.756.756 0 00-.756-.756H2.9a.756.756 0 100 1.512h11.849v5.294c0 .418.338.757.756.757h5.882l1.101 1.418v3.9h-2.092a.756.756 0 000 1.514h2.849a.756.756 0 00.756-.757v-4.916a.757.757 0 00-.159-.463zM5.47 12.731H1.993a.756.756 0 100 1.513H5.47a.756.756 0 100-1.513zM7.21 9.756H.756a.756.756 0 100 1.513H7.21a.756.756 0 100-1.513z"
          fill="#DA9C8C"
        />
        <path
          d="M8.445 6.781H1.992a.756.756 0 100 1.513h6.453a.756.756 0 100-1.513z"
          fill="#DA9C8C"
        />
      </svg>
    </Box>
  )
}
