import React from 'react'

import { Box } from 'src/ui/box'

export const CookieIcon = (): React.ReactElement => {
  return (
    <Box>
      <svg width={40} height={40} viewBox="0 0 40 40" fill="none">
        <path
          d="M37.525 22.82l-1.953.003c-.65 0-1.214-.447-1.357-1.076l-1.112-4.892a1.382 1.382 0 00-.762-.943l-.996-.467a1.377 1.377 0 01-.77-1.516l1.446-7.212a1.37 1.37 0 00-.173-.981L29.87 2.479A20.296 20.296 0 0022.744.168C12.784-1.13 3.231 5.234.675 14.875c-2.982 11.251 4.222 22.5 15.546 24.736 10.8 2.133 21.294-4.737 23.614-15.379L38.38 23.11a1.394 1.394 0 00-.854-.29z"
          fill="#FDE6A1"
        />
        <path
          d="M23.572 34.736c-.12-.023-.236-.053-.355-.08-.916.687-2.109 1-3.322.76a4.149 4.149 0 01-3.313-3.489 20.088 20.088 0 01-5.667-5.462c-.297.029-.6.03-.908-.031a3.104 3.104 0 01-2.456-3.649 3.073 3.073 0 01.785-1.503c-.014-.041-.029-.081-.042-.122-2.098-.563-3.428-2.649-2.998-4.796a4.133 4.133 0 012.128-2.843 19.846 19.846 0 015.485-12.177C6.685 3.73 1.788 9.123.39 16.098c-2.17 10.831 4.916 21.359 15.83 23.513a20.22 20.22 0 0018.524-5.831 20.186 20.186 0 01-11.173.955z"
          fill="#FFD796"
        />
        <path
          d="M17.078 12.361a1.724 1.724 0 011.365 2.027 1.737 1.737 0 01-2.043 1.354 1.724 1.724 0 01-1.364-2.027 1.736 1.736 0 012.042-1.354zM32.897 27.086a1.724 1.724 0 011.365 2.027 1.736 1.736 0 01-2.042 1.354 1.724 1.724 0 01-1.365-2.027 1.736 1.736 0 012.042-1.354zM17.194 25.04c.659.13 1.087.765.955 1.419a1.215 1.215 0 01-1.43.948 1.207 1.207 0 01-.954-1.419c.13-.654.77-1.078 1.43-.948zM28.563 16.035c.658.13 1.086.765.955 1.419a1.216 1.216 0 01-1.43.948 1.207 1.207 0 01-.955-1.42 1.215 1.215 0 011.43-.947zM25.893 4.611c.658.13 1.086.766.955 1.42a1.215 1.215 0 01-1.43.947 1.207 1.207 0 01-.955-1.419 1.216 1.216 0 011.43-.948z"
          fill="#FFD796"
        />
        <path
          d="M19.603 17.61l-1.05 1.147a1.374 1.374 0 00.436 2.174l2.228 1.045c.694.325 1.523.03 1.85-.658l.482-1.012a1.37 1.37 0 00.131-.656l-.058-1.182a1.385 1.385 0 00-1.389-1.312l-1.603.001c-.391 0-.764.165-1.027.453zM21.855 26.761l-1.094 1.197a1.373 1.373 0 00.177 2.016l2.355 1.814c.702.54 1.728.297 2.108-.5l.484-1.017.565-1.188a1.376 1.376 0 00-.795-1.89l-2.31-.806a1.396 1.396 0 00-1.49.374zM9.134 15.862l-1.335 1.46a1.371 1.371 0 00-.126 1.693l1.264 1.878c.46.683 1.416.818 2.05.289l1.689-1.412c.332-.277.514-.692.493-1.121l-.063-1.286a1.38 1.38 0 00-.855-1.207l-1.555-.64a1.396 1.396 0 00-1.562.346zM16.887 5.976l-1.03 1.126a1.373 1.373 0 00.341 2.125l1.567.886a1.398 1.398 0 001.46-.052l1.082-.717c.406-.27.64-.73.616-1.214l-.048-.984a1.382 1.382 0 00-1.117-1.286l-1.571-.31a1.396 1.396 0 00-1.3.426zM31.245 19.679l-.946.627a1.373 1.373 0 00-.383 1.913l.31.46c.331.493.944.72 1.521.563l2.454-.667a1.38 1.38 0 001.021-1.397l-.02-.422a1.385 1.385 0 00-1.39-1.311l-1.797.002c-.274 0-.542.08-.77.232zM28.113 9.025l-1.145 1.019c-.42.374-.57.964-.38 1.492l.56 1.55a1.393 1.393 0 001.838.81l1.926-.79a1.375 1.375 0 00.722-1.87l-.679-1.407a1.385 1.385 0 00-.565-.601l-.661-.375a1.397 1.397 0 00-1.616.172zM12.173 28.09l-1.111.928a1.373 1.373 0 00.137 2.21l1.1.711c.299.193.661.265 1.012.2l1.448-.268a1.378 1.378 0 001.03-1.882l-.162-.389a1.38 1.38 0 00-.432-.563l-1.275-.982a1.398 1.398 0 00-1.747.034z"
          fill="#B28B86"
        />
        <path
          opacity={0.3}
          d="M10.66 21.066l-1.264-1.877a1.371 1.371 0 01.126-1.693l1.335-1.461a1.37 1.37 0 01.395-.29l-.556-.23a1.397 1.397 0 00-1.562.347l-1.335 1.46a1.371 1.371 0 00-.126 1.693l1.264 1.878a1.39 1.39 0 001.903.387 1.39 1.39 0 01-.18-.214zM19.221 9.9l-1.567-.885a1.373 1.373 0 01-.34-2.125l1.029-1.126c.052-.058.115-.101.175-.148l-.337-.066a1.396 1.396 0 00-1.3.426l-1.029 1.126a1.373 1.373 0 00.341 2.125l1.566.886a1.399 1.399 0 001.46-.052l.153-.1c-.05-.022-.103-.033-.151-.06zM22.588 21.844l-2.228-1.045a1.374 1.374 0 01-.435-2.174l1.048-1.147c.125-.137.276-.243.44-.32l-.783.001c-.391 0-.765.165-1.027.453l-1.05 1.147a1.374 1.374 0 00.436 2.174l2.228 1.044c.47.22.997.152 1.393-.126l-.022-.007zM28.444 13.22l-.56-1.55a1.372 1.372 0 01.38-1.492l1.145-1.02c.126-.11.27-.19.42-.248l-.1-.057a1.398 1.398 0 00-1.616.171l-1.145 1.02c-.42.374-.57.964-.38 1.491l.56 1.551a1.392 1.392 0 001.83.812 1.372 1.372 0 01-.534-.678zM31.337 22.804a1.373 1.373 0 01.383-1.913l.946-.627c.228-.151.496-.232.77-.232l1.513-.002a1.39 1.39 0 00-1.137-.585l-1.797.001a1.4 1.4 0 00-.77.233l-.946.627a1.373 1.373 0 00-.383 1.913l.31.46c.31.462.87.678 1.413.575l-.302-.45zM22.648 30.193a1.372 1.372 0 01-.177-2.016l1.094-1.196a1.37 1.37 0 01.513-.338l-.733-.257a1.397 1.397 0 00-1.49.375l-1.094 1.197a1.372 1.372 0 00.177 2.016l2.355 1.814a1.39 1.39 0 001.549.095l-2.194-1.69zM13.707 31.982l-1.1-.71a1.373 1.373 0 01-.137-2.211l1.11-.929c.072-.059.147-.108.225-.15a1.394 1.394 0 00-1.632.107l-1.111.929a1.373 1.373 0 00.137 2.21l1.1.711c.299.193.661.265 1.012.2l.515-.095c-.04-.02-.081-.037-.119-.062z"
          fill="#211515"
        />
        <path
          d="M4.218 25.288a.686.686 0 01-.413-1.099l.953-1.218a.7.7 0 01.975-.122.686.686 0 01.122.968l-.952 1.218a.696.696 0 01-.685.253z"
          fill="#CA8950"
        />
        <path
          d="M14.723 36.502l-.682-.135a.69.69 0 11.271-1.352l.682.134a.69.69 0 11-.271 1.353z"
          fill="#92604B"
        />
        <path
          d="M14.572 23.115a.686.686 0 01-.413-1.099l.952-1.218a.7.7 0 01.975-.122.686.686 0 01.123.968l-.952 1.218a.697.697 0 01-.685.253z"
          fill="#CA8950"
        />
        <path
          d="M12.65 11.487a.698.698 0 01-.29-.132l-1.227-.945a.686.686 0 01-.123-.968c.235-.3.67-.355.975-.122l1.227.946a.686.686 0 01.123.967.697.697 0 01-.685.254zM22.723 3.635l-1.363-.27a.69.69 0 11.271-1.352l1.363.269a.69.69 0 11-.27 1.353z"
          fill="#92604B"
        />
        <path
          d="M23.416 14.315a.692.692 0 01-.535-.494l-.41-1.487a.689.689 0 01.486-.848c.371-.1.753.117.854.483l.41 1.488a.689.689 0 01-.805.858z"
          fill="#CA8950"
        />
        <path
          d="M4.056 13.937a.687.687 0 01.12-1.167l1.396-.675a.698.698 0 01.929.318.687.687 0 01-.32.922l-1.397.675a.699.699 0 01-.728-.073zM28.063 26.483a.693.693 0 01-.442-.294l-1.091-1.621a.686.686 0 01.191-.957.698.698 0 01.964.19l1.091 1.622a.686.686 0 01-.191.957.698.698 0 01-.522.103z"
          fill="#92604B"
        />
        <path
          d="M28.07 33.514a.686.686 0 01-.25-1.25l.817-.54a.698.698 0 01.963.19.686.686 0 01-.192.956l-.817.541a.697.697 0 01-.521.103z"
          fill="#CA8950"
        />
        <path
          d="M35.123 5.378l-.342 1.706a.692.692 0 00.809.813l2.206-.408a.693.693 0 00.554-.544l.18-.894a.69.69 0 00-.546-.811l-2.044-.404a.695.695 0 00-.817.542zM35.512 10.997l-1.04 2.935c-.245.69.096 1.45.777 1.731l1.198.495c.52.214 1.12.096 1.517-.3l.61-.606c.315-.314.46-.76.387-1.197l-.668-4.043a.707.707 0 00-1.088-.47l-1.154.766c-.25.166-.44.408-.54.69z"
          fill="#FDE6A1"
        />
        <path
          d="M38.209 15.256l-1.198-.494a1.376 1.376 0 01-.778-1.731l1.04-2.935c.093-.262.269-.483.493-.647a.697.697 0 00-.562.093l-1.154.765a1.38 1.38 0 00-.538.69l-1.04 2.934c-.245.691.096 1.451.777 1.732l1.198.494c.52.214 1.119.096 1.517-.3l.533-.53c-.097-.017-.195-.032-.288-.071zM37.223 6.813a.692.692 0 01-.808-.812l.206-1.03-.681-.135a.695.695 0 00-.817.542l-.342 1.706a.692.692 0 00.809.813l2.206-.408a.692.692 0 00.554-.544l.071-.353-1.198.221zM37.818 18.431l-.563.72a.687.687 0 00-.12.605l.11.401c.11.4.549.614.935.456l1.244-.51a.69.69 0 00.416-.503l.146-.729a.692.692 0 00-.768-.818l-.938.117a.696.696 0 00-.462.261z"
          fill="#FFD796"
        />
      </svg>
    </Box>
  )
}
