import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Box } from 'src/ui/box'
import { Text, TextProps } from 'src/ui/text'

interface Props {
  data: Product
}

const StyledText = styled(Text)<TextProps>`
  white-space: nowrap;
`

export const Header = ({ data }: Props): React.ReactElement<Props> => {
  const { title, weight } = data

  const price = useMemo(() => {
    const formatter = new Intl.NumberFormat('ru-RU', {
      currency: 'RUB',
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return formatter.format(data.price)
  }, [data.price])

  return (
    <Box
      mb={3}
      width={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text fontSize="30px" textStyle="bold">
        {title}
      </Text>

      <StyledText mr={2} ml="auto" color="greys.1" fontSize="16px">
        {weight}
      </StyledText>

      <StyledText fontSize="22px" textStyle="bold">
        {price}
      </StyledText>
    </Box>
  )
}
