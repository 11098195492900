import React, { ReactElement } from 'react'

import { Text } from 'src/ui/text'
import { ProductTypeEnum } from 'src/enums/product-type.enum'

interface Props {
  type: ProductTypeEnum
}

export const Badge = ({ type }: Props): ReactElement<Props> | null => {
  switch (type) {
    case ProductTypeEnum.Hit:
      return (
        <Text
          mb={1}
          py={1}
          px="10px"
          bg="#3a86ff"
          as="span"
          color="white"
          fontSize="12px"
          textStyle="normal"
          borderRadius="40px"
        >
          ХИТ
        </Text>
      )

    case ProductTypeEnum.New:
      return (
        <Text
          mb={1}
          py={1}
          px="10px"
          bg="#fb5607"
          as="span"
          color="white"
          fontSize="12px"
          textStyle="normal"
          borderRadius="40px"
        >
          НОВИНКА
        </Text>
      )

    case ProductTypeEnum.Gost:
      return (
        <Text
          mb={1}
          ml="auto"
          py={1}
          px="10px"
          bg="#ffbe0b"
          as="span"
          color="white"
          fontSize="12px"
          textStyle="normal"
          borderRadius="40px"
        >
          ГОСТ
        </Text>
      )
    default:
      return null
  }
}
