import React, { useReducer, ReactElement } from 'react'

export const SET_URL = 'SET_URL'
export const CATALOG_REQUEST = 'CATALOG_REQUEST'
export const CATALOG_SUCCESS = 'CATALOG_SUCCESS'

interface GlobalState {
  urlhash: HashTable<string>
  catalog: Product[] | null
  isLoading: boolean
}

interface GlobalStateProviderProps {
  children: React.ReactNode
}

type ActionTypes =
  | { type: typeof CATALOG_REQUEST }
  | { type: typeof CATALOG_SUCCESS; payload: Product[] }
  | { type: typeof SET_URL; payload: { hash: string; url: string } }

const initialState: GlobalState = {
  urlhash: {},
  catalog: null,
  isLoading: false,
}

const rootReducer = (state: GlobalState, action: ActionTypes): GlobalState => {
  switch (action.type) {
    case SET_URL:
      return {
        ...state,
        urlhash: {
          ...state.urlhash,
          [action.payload.hash]: action.payload.url,
        },
      }

    case CATALOG_REQUEST:
      return { ...state, isLoading: true }

    case CATALOG_SUCCESS:
      return { ...state, isLoading: false, catalog: action.payload }

    default:
      return state
  }
}

export const GlobalStateContext = React.createContext<{
  state: GlobalState
  dispatch: React.Dispatch<ActionTypes>
}>({ state: initialState, dispatch: () => null })

export const GlobalStateProvider = ({
  children,
}: GlobalStateProviderProps): ReactElement<GlobalStateProviderProps> => {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  )
}
