import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { SortingTypeEnum } from 'src/enums/sorting-type.enum'

import { Button } from 'src/ui/button'

interface Props {
  type: SortingTypeEnum
  changeSortingType: (type: SortingTypeEnum) => void
}

export const Sorting = ({
  type,
  changeSortingType,
}: Props): ReactElement<Props> => {
  return (
    <Box
      mb={3}
      display="flex"
      maxWidth={['216px', '216px', '216px', 1]}
      alignItems={['stretch', 'stretch', 'stretch', 'center']}
      justifyContent="flex-start"
      flexDirection={['column', 'column', 'column', 'row']}
    >
      <Button
        text="По возрастанию цены"
        isActive={type === SortingTypeEnum.ByPriceAsc}
        onClick={() => changeSortingType(SortingTypeEnum.ByPriceAsc)}
      />

      <Button
        text="По убыванию цены"
        isActive={type === SortingTypeEnum.ByPriceDesc}
        onClick={() => changeSortingType(SortingTypeEnum.ByPriceDesc)}
      />

      <Button
        text="По порядку"
        isActive={type === SortingTypeEnum.ByOrder}
        onClick={() => changeSortingType(SortingTypeEnum.ByOrder)}
      />
    </Box>
  )
}
