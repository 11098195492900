import React, { Fragment } from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Anchor } from 'src/ui/anchor'

export interface BreadCrumbsProps {
  className?: string
  crumbs: {
    name: string
    path?: string
  }[]
}

export const BreadCrumbs = ({
  className,
  crumbs,
}: BreadCrumbsProps): React.ReactElement<BreadCrumbsProps> => {
  return (
    <Box className={className} mt="30px">
      {crumbs.map((crumb, index) =>
        crumb.path ? (
          <Fragment>
            <Anchor
              mx={1}
              key={index}
              to={crumb.path}
              color="greys.1"
              fontSize={1}
              fontFamily="OpenSans"
              lineHeight="24px"
            >
              {crumb.name}
            </Anchor>

            <Text as="span" color="greys.1">
              |
            </Text>
          </Fragment>
        ) : (
          <Text
            mx={1}
            as="span"
            key={index}
            fontSize={1}
            fontFamily="OpenSans"
            lineHeight="24px"
          >
            {crumb.name}
          </Text>
        )
      )}
    </Box>
  )
}
