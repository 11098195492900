import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, BoxProps } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { ArrowDown } from 'src/ui/icons/arrow-down'
import { appTheme } from 'src/theme/theme'

interface Props {
  text: string
  isActive: boolean
  isOpenCatalog: boolean
  toggleCatalog: () => void
}

type RootProps = BoxProps & { isActive: boolean; isOpenCatalog: boolean }

const Root = styled(Box)<RootProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    user-select: none;
    color: ${(p) => appTheme.colors[p.isActive ? 'primary' : 'black']};
  }

  svg {
    margin-left: 4px;
    transition: ${themeGet('transitions.primary')};
    transform: ${(p) => (p.isOpenCatalog ? 'rotate(180deg)' : 'none')};
  }

  svg > path {
    fill: ${(p) => appTheme.colors[p.isActive ? 'primary' : 'black']};
  }

  @media (hover: hover) {
    :hover {
      span {
        color: ${themeGet('colors.primary')};
      }

      svg > path {
        fill: ${themeGet('colors.primary')};
      }
    }
  }
`

export const CatalogButton = ({
  text,
  isActive,
  isOpenCatalog,
  toggleCatalog,
}: Props): ReactElement<Props> => {
  return (
    <Root
      isActive={isActive}
      isOpenCatalog={isOpenCatalog}
      onClick={toggleCatalog}
    >
      <Text fontSize={2} textStyle="bold" as="span">
        {text}
      </Text>

      <ArrowDown />
    </Root>
  )
}
