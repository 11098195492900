import React, { ReactElement } from 'react'

import { menu } from 'src/menu'
import { Content } from 'src/ui/content'

import { MenuItem } from './menu-item'

interface Props {
  isOpenCatalog: boolean
  toggleCatalog: () => void
}

export const Menu = ({
  isOpenCatalog,
  toggleCatalog,
}: Props): ReactElement<Props> => {
  return (
    <Content
      as="nav"
      bg="white"
      zIndex={2}
      gridArea="menu"
      borderTop={1}
      borderTopColor="greys.0"
      borderBottom={1}
      borderBottomColor="greys.0"
    >
      {menu.map(({ route, title }, index) => (
        <MenuItem
          key={index}
          to={route}
          text={title}
          isOpenCatalog={isOpenCatalog}
          toggleCatalog={toggleCatalog}
        />
      ))}
    </Content>
  )
}
