import React, { useRef, useState, ReactElement } from 'react'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Image } from 'src/ui/image'
import { Content } from 'src/ui/content'
import { ArrowLeft } from 'src/ui/icons/arrow-left'
import { ArrowRight } from 'src/ui/icons/arrow-right'

import { Button } from './button'
import { ProductSlide } from './product-slide'

interface Props {
  data: Product[]
}

export const NewGoods = ({ data }: Props): ReactElement<Props> | null => {
  const swiperRef = useRef<SwiperCore>()

  const init = (instance: SwiperCore) => (swiperRef.current = instance)

  const goToPrev = () => swiperRef.current && swiperRef.current.slidePrev()
  const goToNext = () => swiperRef.current && swiperRef.current.slideNext()

  const [activeIdx, setActiveIdx] = useState(0)

  return data.length > 0 ? (
    <Content bg="white" gridArea="newGoods">
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Image
          src={data?.[activeIdx]?.photos?.[0]}
          width={1}
          height="auto"
          minWidth="382px"
          maxWidth="790px"
          maxHeight="540px"
        />
      </Box>

      <Box
        flex={['1', '1', '0.8', '0.6']}
        pl={4}
        height={1}
        position="relative"
      >
        <Text
          mt={6}
          color="primary"
          textStyle="bold"
          fontSize={8}
          lineHeight="65px"
        >
          Новинки
        </Text>

        <Text
          my={4}
          color="secondary"
          textStyle="bold"
          fontSize="62px"
          lineHeight="84px"
        >
          {activeIdx < 10 ? `0${activeIdx + 1}` : activeIdx + 1}
        </Text>

        <Text color="black" textStyle="bold" fontSize={5} lineHeight="30px">
          {data?.[activeIdx]?.title}
        </Text>

        <Text
          mt={2}
          color="black"
          textStyle="normal"
          fontSize={3}
          lineHeight="28px"
        >
          {data?.[activeIdx]?.description}
        </Text>

        <Box
          mb={5}
          width={1}
          maxWidth="320px"
          display="flex"
          alignItems="stretch"
          position="absolute"
          bottom={0}
        >
          <Swiper
            loop={true}
            className="new-goods-swiper"
            spaceBetween={10}
            slidesPerView={2}
            slidesPerGroup={1}
            slidesPerColumnFill="row"
            breakpoints={{
              320: {
                spaceBetween: 4,
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              992: {
                spaceBetween: 30,
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
            }}
            onSwiper={init}
            onSlideChange={(swiper) => setActiveIdx(swiper.realIndex)}
          >
            {data.map((product) => (
              <SwiperSlide key={product.id}>
                <ProductSlide product={product} />
              </SwiperSlide>
            ))}
          </Swiper>

          <Box
            ml={[1, 1, 3, '30px']}
            minWidth={['56px', '56px', '56px', '36px']}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Button icon={ArrowLeft} onClick={goToPrev} />
            <Button icon={ArrowRight} onClick={goToNext} />
          </Box>
        </Box>
      </Box>
    </Content>
  ) : null
}
