import React, { ReactElement } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Text } from 'src/ui/text'
import { Box, BoxProps } from 'src/ui/box'

import { appTheme } from 'src/theme/theme'

interface Props {
  to: string
  text: string
  icon: React.FunctionComponent
}

const Root = styled(Box)<BoxProps & { isActive: boolean }>`
  height: 48px;
  cursor: pointer;
  background-color: ${(p) => (p.isActive ? appTheme.colors.primary : 'none')};

  span {
    padding-left: 20px;
    color: ${(p) => appTheme.colors[p.isActive ? 'white' : 'black']};
  }

  svg {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;

    path {
      fill: ${(p) => appTheme.colors[p.isActive ? 'white' : 'primary']};
    }
  }

  @media (hover: hover) {
    :hover {
      opacity: ${(p) => (!p.isActive ? 0.6 : 1)};

      span {
        color: ${(p) => (!p.isActive ? appTheme.colors.primary : 'white')};
      }
    }
  }
`

export const MenuItem = ({ to, text, icon }: Props): ReactElement<Props> => {
  const history = useHistory()

  const location = useLocation()

  return (
    <Root
      display="flex"
      alignItems="center"
      isActive={location.pathname === to}
      onClick={() => history.push(to)}
    >
      <Text as="span" fontSize={2} textStyle="normal">
        {React.createElement(icon)}
        {text}
      </Text>
    </Root>
  )
}
