import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Link } from 'src/ui/link'

export const Contacts = (): ReactElement => {
  return (
    <Box display={['none', 'none', 'none', 'block', 'block']}>
      <Link
        href="tel:83473206369"
        color="primary"
        textStyle="bold"
        fontSize={5}
        lineHeight="30px"
      >
        8 (3473) 20-63-69
      </Link>

      <Text
        mt={1}
        color="black"
        textStyle="normal"
        fontSize={1}
        lineHeight="22px"
      >
        Пн-Вс 9:00-20:00
      </Text>
    </Box>
  )
}
