import styled from 'styled-components'
import {
  color,
  space,
  textStyle,
  colorStyle,
  typography,
  compose,
  SpaceProps,
  ColorProps,
  TextStyleProps,
  TypographyProps,
} from 'styled-system'

export type LinkProps = SpaceProps &
  ColorProps &
  TextStyleProps &
  TypographyProps & { href: string }

export const Link = styled.a<LinkProps>`
  text-decoration: none;

  @media (hover: hover) {
    :hover {
      opacity: 0.8;
    }
  }

  ${compose(space, color, textStyle, colorStyle, typography)};
`
