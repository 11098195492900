import React, { ReactElement, useState } from 'react'
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Card } from 'src/ui/card/card'
import { Content } from 'src/ui/content'
import { ArrowLeft } from 'src/ui/icons/arrow-left'
import { ArrowRight } from 'src/ui/icons/arrow-right'

import { CardTypes } from 'src/enums/card-types.enum'

import { Button } from './button'

import 'swiper/swiper-bundle.min.css'

interface Props {
  data: Product[]
  bgColor?: string
}

const SwiperRoot = styled(Swiper)`
  width: 100%;
`

export const Proposals = ({
  data,
  bgColor = 'greys.2',
}: Props): ReactElement<Props> => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const init = (instance: SwiperCore) => !swiper && setSwiper(instance)

  const goToPrev = () => swiper && swiper.slidePrev()
  const goToNext = () => swiper && swiper.slideNext()

  return (
    <Content
      bg={bgColor}
      overflowX="hidden"
      gridArea="proposals"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        mb="30px"
        width={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text mr="auto" fontSize={7} textStyle="bold">
          Рекомендуем попробовать
        </Text>

        <Box
          width="104px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button icon={ArrowLeft} onClick={goToPrev} />
          <Button icon={ArrowRight} onClick={goToNext} />
        </Box>
      </Box>

      <SwiperRoot
        spaceBetween={30}
        slidesPerView={3}
        slidesPerGroup={3}
        slidesPerColumnFill="row"
        breakpoints={{
          320: {
            spaceBetween: 10,
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          576: {
            spaceBetween: 30,
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          1400: {
            spaceBetween: 30,
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        }}
        onSwiper={init}
      >
        {data.map((product, index) => (
          <SwiperSlide key={index}>
            <Card
              type={
                isTabletOrMobile ? CardTypes.Vertical : CardTypes.Horizontal
              }
              product={product}
              isVisibleBadges={false}
            />
          </SwiperSlide>
        ))}
      </SwiperRoot>
    </Content>
  )
}
