import React from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { TimeIcon } from 'src/ui/icons/time'

export const Schedule = (): React.ReactElement => {
  return (
    <Box p={4} mb={5} display="flex" border={1} borderColor="greys.0">
      <TimeIcon />

      <Box ml={4}>
        <Text
          pb={3}
          color="black"
          fontSize={4}
          lineHeight="30px"
          fontWeight="bold"
        >
          Режим работы
        </Text>

        <Text color="black" fontSize={3} lineHeight="28px" fontWeight="normal">
          Пн-Вс 9:00-20:00
        </Text>
      </Box>
    </Box>
  )
}
