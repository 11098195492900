import { useEffect } from 'react'

import { api } from 'src/services/api'
import { useGlobalState } from 'src/hooks/use-global-state'
import { CATALOG_REQUEST, CATALOG_SUCCESS } from 'src/core/global-state'

export const useCatalog = () => {
  const { dispatch } = useGlobalState()

  useEffect(() => {
    const fetchCatalog = async () => {
      dispatch({ type: CATALOG_REQUEST })

      try {
        const response = await api.fetch('catalog')

        dispatch({
          type: CATALOG_SUCCESS,
          payload: response.filter((product) => !product.hidden) as Product[],
        })
      } catch (error) {
        // TODO: add failure action
        console.log(error)
      }
    }

    fetchCatalog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
