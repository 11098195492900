import styled from 'styled-components'

import { Box, BoxProps } from 'src/ui/box'

type Props = BoxProps

export const CardContainer = styled(Box)<Props>`
  user-select: none;
  transition: all 0.1s ease-in-out;

  @media (hover: hover) {
    :hover {
      transform: scale(0.96);
    }
  }
`

CardContainer.displayName = 'CardContainer'
