export const routes = {
  root: '/',
  catalog: '/catalog',
  delivery: '/delivery',
  licenses: '/licenses',
  contacts: '/contacts',
  about: '/about',
}

export const catalogRoutes = {
  puffCookies: '/puff_cookies',
  butterCookies: '/butter_cookies',
  shortbread: '/shortbread',
  pastries: '/pastries',
  cupcakes: '/cupcakes',
  custardBiscuits: '/custard_biscuits',
  pies: '/pies',
  bakery: '/bakery',
}
