import * as React from 'react'

import { Box } from 'src/ui/box'

export const CakeIcon = (): React.ReactElement => {
  return (
    <Box as="span">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <path
          d="M19.904 2.066a.703.703 0 00-.59-.058c-2.005.702-4.004 1.398-5.605 1.955a3.515 3.515 0 00-1.951-1.09 3.713 3.713 0 00-2.011-2.7L9.549.074a.703.703 0 00-.63 1.258l.199.1c.606.302 1.036.838 1.207 1.47a3.522 3.522 0 00-2.722 3.183c-2.29.796-4.722 1.642-7.132 2.486A.703.703 0 000 9.234v7.5c0 .291.182.563.472.664l18.844 6.563a.7.7 0 00.478-.006l.07-.016c.39-.09 1.422-.329 2.356-1.097 1.181-.97 1.78-2.395 1.78-4.233V9.234c0-.194-.047-4.782-4.096-7.168zm-8.795 2.153c1.164 0 2.11.946 2.11 2.11 0 1.162-.946 2.108-2.11 2.108A2.112 2.112 0 019 6.328c0-1.163.946-2.11 2.11-2.11zM7.797 7.506a3.521 3.521 0 003.312 2.338 3.52 3.52 0 003.329-4.646c1.467-.51 3.235-1.126 5.024-1.751 3.095 2.028 3.132 5.749 3.132 5.787 0 3.262-2.35 5.216-3.145 5.784l-3.889-1.361a.703.703 0 00-.935.663v2.883a.704.704 0 01-1.406 0v-3.867c0-.3-.19-.566-.473-.664L2.84 9.232c1.681-.587 3.352-1.168 4.957-1.726zm4.016 8.591L1.405 12.485v-2.261l10.406 3.613v2.261zM1.405 13.973l10.444 3.626a2.113 2.113 0 002.072 1.713 2.1 2.1 0 001.372-.509l3.55 1.243v2.261L1.406 16.235v-2.262zm17.438 4.583L16 17.56c.02-.117.031-.236.031-.358v-1.892l2.813.985v2.26zm3.75.053c0 1.924-.769 3.156-2.344 3.75v-6.184a9.56 9.56 0 002.344-2.397v4.831z"
          fill="#DA9C8C"
        />
        <path
          d="M19.313 6.89a.703.703 0 100-1.406.703.703 0 000 1.407zM20.25 10.172a.703.703 0 100-1.406.703.703 0 000 1.406zM17.906 12.984a.703.703 0 100-1.406.703.703 0 000 1.406zM16.5 9.703a.703.703 0 100-1.406.703.703 0 000 1.406z"
          fill="#DA9C8C"
        />
      </svg>
    </Box>
  )
}
