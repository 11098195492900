import * as React from 'react'

export const ArrowLeft = (): React.ReactElement => {
  return (
    <svg width={14} height={24} viewBox="0 0 14 24" fill="none">
      <path
        d="M13.548.435a1.585 1.585 0 00-2.183 0L.452 10.95a1.448 1.448 0 000 2.102l10.913 10.513c.603.581 1.58.581 2.183 0a1.449 1.449 0 000-2.102L3.726 12l9.822-9.462a1.449 1.449 0 000-2.103z"
        fill="#da9c8c"
      />
    </svg>
  )
}
