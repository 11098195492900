import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { Image } from 'src/ui/image'

interface Props {
  product: Product
}

export const ProductSlide = ({ product }: Props): ReactElement<Props> => (
  <Box width="100px" height="100px">
    <Image src={product.photos[0]} width={1} height={1} />
  </Box>
)
