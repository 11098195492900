import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Content } from 'src/ui/content'

import { CookIcon } from 'src/ui/icons/cook'
import { ClockIcon } from 'src/ui/icons/delivery'
import { CakeIcon } from 'src/ui/icons/cake'

const items = [
  { icon: CookIcon, text: 'Собственное производство с 2010 года' },
  { icon: ClockIcon, text: 'Бесперебойная доставка 7 дней в неделю' },
  { icon: CakeIcon, text: 'Образцы продукции бесплатно!' },
]

export const Advantages = (): ReactElement => {
  return (
    <Content
      bg="greys.4"
      zIndex={2}
      gridArea="advantages"
      display={['none', 'none', 'none', 'none', 'flex']}
      borderBottom={1}
      borderBottomColor="greys.0"
    >
      {items.map(({ icon: Icon, text }, index) => (
        <Box key={index} display="flex" alignItems="center">
          <Icon />

          <Text ml={2} as="span" textStyle="normal" fontSize={1}>
            {text}
          </Text>
        </Box>
      ))}
    </Content>
  )
}
