import React from 'react'
import styled from 'styled-components'

import { Text } from './text'

const Root = styled.div`
  height: 100vh;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  text-align: center;
`

const Emoji = styled.span.attrs({
  children: '🙈',
  role: 'img',
  'aria-label': 'monkey',
})``

export const NotFoundPage = () => (
  <Root>
    <Container>
      <Emoji />

      <Text mt={4} fontSize={8}>
        404
      </Text>

      <Text mt={2} fontSize={6}>
        Страница не найдена
      </Text>
    </Container>
  </Root>
)
