import React, { ReactElement, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Content } from 'src/ui/content'
import { Common } from 'src/common/common'
import { useGlobalState } from 'src/hooks/use-global-state'
import { SortingTypeEnum } from 'src/enums/sorting-type.enum'
import { ProductCategoryEnum } from 'src/enums/product-category.enum'

import { Menu } from './ui/menu'
import { Catalog } from './ui/catalog'

export const CatalogPage = (): ReactElement => {
  const location = useLocation()

  const { state } = useGlobalState()

  const [sortingType, setSortingType] = useState<SortingTypeEnum>(
    SortingTypeEnum.ByPriceAsc
  )

  const data = useMemo(() => {
    const path = location.pathname.split('/').slice(-1)?.[0] ?? ''
    const category = path.toUpperCase()

    const result: Product[] = []

    if (state.catalog) {
      if (Object.values(ProductCategoryEnum).includes(category)) {
        result.push(
          ...state.catalog.filter((product) => category === product.category)
        )
      } else {
        result.push(...state.catalog)
      }

      return result.sort((a, b) => {
        switch (sortingType) {
          case SortingTypeEnum.ByPriceAsc:
            return a.price - b.price

          case SortingTypeEnum.ByPriceDesc:
            return b.price - a.price

          case SortingTypeEnum.ByOrder:
            return a.title.localeCompare(b.title)

          default:
            return 0
        }
      })
    }

    return result
  }, [state.catalog, location.pathname, sortingType])

  const changeSortingType = (type: SortingTypeEnum) => setSortingType(type)

  return (
    <Common>
      <Content
        pt={30}
        pb={70}
        display="grid"
        gridGap={30}
        gridArea="content"
        alignItems="flex-start"
        gridTemplateRows={['1fr']}
        gridTemplateColumns={['270px 1fr']}
        gridTemplateAreas="'menu catalog'"
      >
        <Menu />

        {data && (
          <Catalog
            data={data}
            sortingType={sortingType}
            changeSortingType={changeSortingType}
          />
        )}
      </Content>
    </Common>
  )
}
