import * as React from 'react'

export const ButterCookie = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M9.356 6c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205C10.531 6.552 10.031 6 9.356 6zM14.856 6c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zM9.356 10.995c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zM14.856 10.995c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zM9.356 15.99c-.63 0-1.234.555-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.652-.53-1.205-1.205-1.205zM14.856 15.99c-.63 0-1.234.555-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.652-.53-1.205-1.205-1.205z"
        fill="#B28B86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.356 6c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205C10.531 6.552 10.031 6 9.356 6zm5.5 0c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zm-5.5 4.995c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zm5.5 0c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zm-5.5 4.995c-.63 0-1.234.555-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.652-.53-1.205-1.205-1.205zm5.5 0c-.63 0-1.234.555-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.652-.53-1.205-1.205-1.205z"
        fill="#B28B86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.974 1.88A3.99 3.99 0 0017.306.86c-.986 0-1.935.369-2.662 1.014A4.022 4.022 0 0011.98.86c-.986 0-1.935.369-2.662 1.014A4.021 4.021 0 006.656.86 4.01 4.01 0 002.65 4.866c0 .803.239 1.578.682 2.236a3.992 3.992 0 00-.682 2.235c0 .987.37 1.936 1.015 2.663a4.022 4.022 0 00-1.015 2.662c0 .804.239 1.58.682 2.236a3.993 3.993 0 00-.682 2.236 4.01 4.01 0 004.006 4.006c.987 0 1.935-.369 2.663-1.014a4.022 4.022 0 002.662 1.014c.987 0 1.935-.369 2.663-1.014a4.021 4.021 0 002.662 1.014 3.99 3.99 0 002.668-1.02c.19-.125.632-.522.874-1.116a3.993 3.993 0 00-.217-4.106 3.992 3.992 0 00.681-2.236c0-.986-.369-1.935-1.014-2.662a4.022 4.022 0 001.014-2.663c0-.803-.239-1.578-.682-2.235a3.993 3.993 0 00.218-4.106 28.46 28.46 0 00-.873-1.115zm-4.761 18.786a2.606 2.606 0 002.093 1.063 2.597 2.597 0 002.595-2.595c0-.652-.244-1.277-.687-1.757l-.441-.479.441-.478c.443-.48.687-1.104.687-1.758 0-.822-.398-1.605-1.063-2.093l-.776-.57.776-.568a2.606 2.606 0 001.063-2.094c0-.653-.244-1.277-.687-1.757l-.44-.478.44-.479c.443-.48.687-1.104.687-1.757a2.597 2.597 0 00-2.595-2.595c-.823 0-1.605.397-2.093 1.063l-.57.776-.568-.776A2.606 2.606 0 0011.98 2.27c-.823 0-1.605.397-2.093 1.063l-.57.776-.569-.776A2.606 2.606 0 006.656 2.27a2.597 2.597 0 00-2.594 2.595c0 .653.244 1.277.686 1.757l.441.479-.44.478a2.586 2.586 0 00-.687 1.757c0 .823.397 1.606 1.062 2.094L5.9 12l-.776.57a2.606 2.606 0 00-1.062 2.092c0 .654.244 1.278.686 1.758l.441.478-.44.479a2.586 2.586 0 00-.687 1.757 2.597 2.597 0 002.594 2.595c.823 0 1.605-.397 2.093-1.063l.57-.776.569.776a2.606 2.606 0 002.093 1.063c.823 0 1.606-.397 2.094-1.063l.569-.776.569.776z"
        fill="#B28B86"
      />
    </svg>
  )
}
