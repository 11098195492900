import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { ProductTabsEnum } from 'src/enums/product-tabs.enum'

import { Button } from 'src/ui/button'

interface Props {
  activeTab: ProductTabsEnum
  changeActiveTab: (tab: ProductTabsEnum) => void
}

export const Tabs = ({
  activeTab,
  changeActiveTab,
}: Props): ReactElement<Props> => {
  return (
    <Box mb={30} display="flex" alignItems="center" justifyContent="flex-start">
      <Button
        text="Состав"
        isActive={activeTab === ProductTabsEnum.Composition}
        onClick={() => changeActiveTab(ProductTabsEnum.Composition)}
      />

      <Button
        text="Срок годности"
        isActive={activeTab === ProductTabsEnum.StorageConditions}
        onClick={() => changeActiveTab(ProductTabsEnum.StorageConditions)}
      />

      <Button
        text="Энергетическая ценность на 100 г"
        isActive={activeTab === ProductTabsEnum.EnergyContent}
        onClick={() => changeActiveTab(ProductTabsEnum.EnergyContent)}
      />
    </Box>
  )
}
