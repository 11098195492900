import * as React from 'react'

export const PuffCookie = (): React.ReactElement => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M2.798 16.056a12.434 12.434 0 003.958 3.68c1.686.987 3.516 1.506 5.29 1.506 2.114 0 4.224-.71 6.103-2.058a.737.737 0 10-.86-1.198c-1.62 1.162-3.432 1.78-5.242 1.78-3.001 0-6.147-1.789-8.031-4.542-1.44-2.103-2.341-4.663-2.341-6.693 0-1.27.414-2.346 1.152-3.107.738-.761 1.786-1.19 3.022-1.19 1.52 0 2.928.983 3.88 2.634.516.893.896 2.143 1.15 3.322.252 1.181.381 2.315.383 2.986v.008a4.544 4.544 0 000 .339v.017c-.025.442-.14.886-.333 1.234-.197.356-.465.599-.812.625l-.01.001h-.009c-.473-.009-.991-.252-1.495-.646-.508-.396-1.022-.96-1.493-1.648-.985-1.44-1.685-3.314-1.685-4.575 0-.212.029-.337.055-.408l.04-.11.116-.02a.983.983 0 01.12-.009l.062-.002.052.033c.108.069.34.258.611.726a.738.738 0 001.278-.736c-.572-.992-1.278-1.497-1.91-1.497-.238 0-.686.002-1.113.28-.345.225-.786.705-.786 1.743 0 1.546.757 3.674 1.943 5.408 1.318 1.926 2.826 2.935 4.23 2.936h.037c.655-.03 1.237-.324 1.688-.849L12 15.85l.152.176c.451.525 1.033.818 1.688.848l.037.001c1.404 0 2.912-1.01 4.23-2.936 1.186-1.734 1.943-3.862 1.943-5.408 0-1.038-.44-1.518-.786-1.743-.427-.278-.875-.28-1.113-.28-.632 0-1.338.505-1.91 1.497a.738.738 0 001.278.736c.27-.468.503-.657.611-.726l.052-.033.061.002a.972.972 0 01.122.01l.115.019.04.11c.027.07.055.196.055.408 0 1.261-.7 3.135-1.685 4.575-.47.687-.985 1.252-1.493 1.648-.504.394-1.021.637-1.495.646h-.02c-.346-.027-.614-.27-.81-.626a2.946 2.946 0 01-.334-1.233V13.522a4.491 4.491 0 000-.339v-.008c.001-.671.13-1.805.384-2.986.253-1.179.633-2.429 1.148-3.322.953-1.651 2.36-2.635 3.881-2.635 1.236 0 2.284.43 3.022 1.19.738.762 1.152 1.839 1.152 3.108 0 2.03-.902 4.59-2.34 6.692a.737.737 0 101.217.833c1.606-2.348 2.598-5.236 2.598-7.525 0-1.634-.565-3.103-1.58-4.144-1.022-1.047-2.464-1.629-4.069-1.629-2.028 0-3.913 1.213-5.158 3.373-.312.54-.579 1.177-.803 1.852l-.19.57-.19-.57c-.224-.675-.491-1.311-.803-1.852-1.245-2.16-3.13-3.373-5.158-3.373-1.605 0-3.047.582-4.068 1.629C.765 5.427.2 6.897.2 8.53c0 2.29.991 5.177 2.598 7.525z"
        fill="#B28B86"
      />
    </svg>
  )
}
