import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Card } from 'src/ui/card/card'
import { Content } from 'src/ui/content'

import { CardTypes } from 'src/enums/card-types.enum'
import { ProductTypeEnum } from 'src/enums/product-type.enum'

interface Props {
  data: Product[]
}

export const Hits = ({ data }: Props): ReactElement<Props> => {
  return (
    <Content
      bg="white"
      gridArea="hits"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Text
        mt={70}
        mb={30}
        mr="auto"
        fontSize={7}
        textStyle="bold"
        lineHeight="44px"
      >
        Хиты продаж
      </Text>

      <Box
        mb={70}
        width={1}
        display="grid"
        gridGap="2em"
        gridTemplateColumns={[
          'repeat(2, 48%)',
          'repeat(2, 48%)',
          'repeat(2, 48%)',
          'repeat(3, 31%)',
          'repeat(4, 23%)',
        ]}
      >
        {data.map((product, index) => (
          <Card
            key={index}
            type={CardTypes.Vertical}
            product={product}
            hiddenProductTypes={[ProductTypeEnum.Hit]}
          />
        ))}
      </Box>
    </Content>
  )
}
