import React from 'react'
import {
  grid,
  background,
  color,
  border,
  flexbox,
  layout,
  position,
  space,
  typography,
  shadow,
  overflow,
  compose,
  GridProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
  ShadowProps,
  FlexboxProps,
  BordersProps,
  OverflowProps,
  PositionProps,
  TypographyProps,
  BackgroundProps,
} from 'styled-system'
import styled from 'styled-components'

export type BoxProps = GridProps &
  ColorProps &
  SpaceProps &
  LayoutProps &
  ShadowProps &
  FlexboxProps &
  BordersProps &
  OverflowProps &
  PositionProps &
  TypographyProps &
  BackgroundProps & {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
    children?: React.ReactNode | React.ReactNodeArray
    cursor?: string
  }

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;

  cursor: ${(p) => p.cursor || 'inherit'};

  ${compose(
    grid,
    color,
    space,
    layout,
    border,
    shadow,
    flexbox,
    overflow,
    position,
    typography,
    background
  )}
`

Box.displayName = 'Box'
