import * as React from 'react'

export const Pie = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M23.995 16.851a3.918 3.918 0 00-3.989-3.915 8.62 8.62 0 00-2.847-3.835 8.462 8.462 0 00-5.16-1.734c-1.88 0-3.664.6-5.158 1.734a8.62 8.62 0 00-2.848 3.835 3.922 3.922 0 00-2.841 1.146A3.89 3.89 0 00.005 16.85a3.89 3.89 0 001.317 2.929l.744 2.536A2.357 2.357 0 004.316 24h15.369a2.356 2.356 0 002.248-1.684l.745-2.536a3.89 3.89 0 001.317-2.929zM2.146 15.076a2.514 2.514 0 012.215-.696l.62.11.177-.604c.887-3.01 3.7-5.113 6.842-5.113 3.142 0 5.955 2.103 6.842 5.113l.178.604.619-.11a2.514 2.514 0 012.215.696c.474.474.735 1.104.735 1.775 0 .67-.261 1.3-.735 1.775a2.494 2.494 0 01-1.775.735 2.494 2.494 0 01-2.106-1.144l-.59-.906-.59.906a2.494 2.494 0 01-2.106 1.144 2.494 2.494 0 01-2.102-1.139l-.589-.9-.588.9a2.494 2.494 0 01-2.103 1.14 2.494 2.494 0 01-2.104-1.14l-.588-.9-.589.9a2.513 2.513 0 01-3.878.404 2.494 2.494 0 01-.735-1.775c0-.67.261-1.3.735-1.775zm18.438 6.844a.943.943 0 01-.9.674H4.316a.943.943 0 01-.9-.674l-.367-1.253a3.917 3.917 0 003.565-.971 3.889 3.889 0 002.691 1.071c1.01 0 1.962-.38 2.692-1.071a3.89 3.89 0 002.692 1.071c1.011 0 1.964-.38 2.695-1.075a3.889 3.889 0 002.696 1.075c.297 0 .59-.034.872-.098l-.367 1.251z"
        fill="#B28B86"
      />
      <path
        d="M9.206 14c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zM11.206 10c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zM14.206 13c-.63 0-1.234.554-1.205 1.205.03.653.53 1.205 1.205 1.205.63 0 1.234-.554 1.205-1.205-.03-.653-.53-1.205-1.205-1.205zM6.728 6.172h1.406c0-.369.126-.52.354-.796.286-.344.678-.817.678-1.693 0-.876-.392-1.348-.678-1.693-.228-.275-.354-.427-.354-.795H6.728c0 .876.392 1.348.678 1.693.228.275.354.427.354.795 0 .369-.126.52-.354.795-.286.345-.678.818-.678 1.694zM10.78 4.977h1.407c0-.369.126-.52.354-.796.286-.344.678-.817.678-1.693 0-.876-.392-1.348-.678-1.693-.228-.275-.354-.427-.354-.795H10.78c0 .876.392 1.348.678 1.693.228.275.354.427.354.795 0 .369-.126.52-.354.795-.286.345-.678.818-.678 1.694zM14.834 6.172h1.406c0-.369.126-.52.354-.796.286-.344.678-.817.678-1.693 0-.876-.392-1.348-.678-1.693-.228-.275-.354-.427-.354-.795h-1.406c0 .876.392 1.348.678 1.693.228.275.354.427.354.795 0 .369-.126.52-.354.795-.286.345-.678.818-.678 1.694z"
        fill="#B28B86"
      />
    </svg>
  )
}
