export const appConfig = {
  submitDelay: 800,
  priceUrl:
    'https://storage.googleapis.com/sweet-shop-a5d80.appspot.com/price.xls',
  licensesUrls: [
    'https://storage.googleapis.com/sweet-shop-a5d80.appspot.com/certificates/cert_1.jpeg',
    'https://storage.googleapis.com/sweet-shop-a5d80.appspot.com/certificates/cert_2.jpeg',
    'https://storage.googleapis.com/sweet-shop-a5d80.appspot.com/certificates/cert_3.jpeg',
    'https://storage.googleapis.com/sweet-shop-a5d80.appspot.com/certificates/cert_4.jpeg',
  ],
}
