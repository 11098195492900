import React, { useMemo, ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import noop from 'lodash/noop'

import { Anchor } from 'src/ui/anchor'
import { Box, BoxProps } from 'src/ui/box'
import { routes, catalogRoutes } from 'src/routes'
import { appTheme } from 'src/theme/theme'

import { PriceButton } from './price-button'
import { CatalogButton } from './catalog-button'

interface Props {
  to: string | null
  text: string
  icon?: React.FunctionComponent
  isOpenCatalog: boolean
  toggleCatalog: () => void
}

const Root = styled(Box)<BoxProps & { isActive: boolean }>`
  a {
    user-select: none;
    color: ${(p) => appTheme.colors[p.isActive ? 'primary' : 'black']};
  }

  @media (hover: hover) {
    :hover {
      a {
        color: ${themeGet('colors.primary')};
      }
    }
  }
`

export const MenuItem = ({
  to,
  text,
  icon,
  isOpenCatalog,
  toggleCatalog,
}: Props): ReactElement<Props> => {
  const location = useLocation()

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 812px)',
  })

  const title = useMemo(() => {
    if (isTabletOrMobile && to === routes.licenses) {
      return text.split(' ').slice(0, 1).join()
    }

    return text
  }, [isTabletOrMobile, to, text])

  const isCatalogRoute = useMemo(() => {
    const parts = location.pathname.split('/')

    if (parts.length === 2) {
      return parts.slice(-1).join() === 'catalog'
    }

    if (parts.length === 3) {
      return (
        parts[1] === 'catalog' &&
        Object.values(catalogRoutes).includes(`/${parts[2]}`)
      )
    }

    return false
  }, [location.pathname])

  switch (to) {
    case routes.catalog:
      return (
        <CatalogButton
          text={text}
          isActive={isCatalogRoute}
          isOpenCatalog={isOpenCatalog || isCatalogRoute}
          toggleCatalog={isCatalogRoute ? noop : toggleCatalog}
        />
      )

    case null:
      return <PriceButton text={text} />

    default:
      return (
        <Root
          display="flex"
          alignItems="center"
          isActive={location.pathname === to}
        >
          <Anchor to={to} fontSize={2} textStyle="bold">
            {title}
            {icon && React.createElement(icon)}
          </Anchor>
        </Root>
      )
  }

  // return to === routes.catalog ? (
  //   <CatalogButton
  //     text={text}
  //     isActive={location.pathname.includes(to)}
  //     isOpenCatalog={isOpenCatalog || isCatalogRoute}
  //     toggleCatalog={isCatalogRoute ? noop : toggleCatalog}
  //   />
  // ) : (
  //   <Root
  //     display="flex"
  //     alignItems="center"
  //     isActive={location.pathname === to}
  //   >
  //     <Anchor to={to} fontSize={2} textStyle="bold">
  //       {text}
  //       {icon && React.createElement(icon)}
  //     </Anchor>
  //   </Root>
  // )
}
