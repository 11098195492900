import React, { ReactElement, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import trim from 'lodash/trim'

import { Box } from 'src/ui/box'
import { Input } from 'src/ui/input'
import { SearchIcon } from 'src/ui/icons/search'
import { api } from 'src/services/api'
import { appConfig } from 'src/config/app.config'

import { SearchMenu } from './search-menu'

export const Search = (): ReactElement => {
  const [value, setValue] = useState('')
  const [results, setResults] = useState<Product[]>([])

  const [isOpenSearchResult, setIsOpenSearchResult] = useState(false)

  const show = () => setIsOpenSearchResult(true)
  const hide = () => setIsOpenSearchResult(false)

  const [handleSubmit, cancel] = useDebouncedCallback(async (query: string) => {
    try {
      const response = await api.fetch(
        'catalog',
        {
          field: 'keywords',
          op: 'array-contains',
          value: query,
        },
        'title',
        10
      )

      setResults(response.filter((product) => !product.hidden) as Product[])

      show()
    } catch (error) {
      console.log(error)
    }
  }, appConfig.submitDelay)

  const handleFocus = () => {
    if (results.length > 0) {
      show()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)

    const normalized = trim(event.target.value.toLowerCase())

    if (normalized.length >= 3) {
      handleSubmit(normalized)
    } else {
      hide()
      cancel()
    }
  }

  return (
    <Box
      width="388px"
      minWidth="320px"
      maxWidth="388px"
      height={1}
      position="relative"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        width={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={1}
        borderBottomColor="greys.0"
      >
        <Input
          p={2}
          type="text"
          width="100%"
          placeholder="Поиск"
          placeholderColor="colors.greys.1"
          value={value}
          onFocus={handleFocus}
          onChange={handleChange}
        />

        <SearchIcon />
      </Box>

      {isOpenSearchResult && <SearchMenu list={results} onHide={hide} />}
    </Box>
  )
}
