import { normalize } from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  font-size: 16px;
`

export const appTheme = {
  breakpoints: ['576px', '768px', '992px', '1200px'],
  space: [0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512],
  sizes: [0, '100%', 4, 8, 16, 24, 32, 48, 64, 80, 128, 256, 512],
  radii: [0, 2, 4, 8, 16, '50%', '100%'],
  borders: [0, '1px solid', '2px solid', '4px solid'],
  zIndices: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 32, 48],
  fontWeights: [400, 500, 600, 700],
  lineHeights: {
    solid: 1,
    text: 1.15,
    title: 1.25,
    copy: 1.5,
    double: 2,
  },
  fonts: {
    OpenSans: 'Open Sans, sans-serif',
  },
  colors: {
    primary: '#DA9C8C',
    secondary: '#F9DAA2',
    white: '#FFFFFF',
    black: '#211515',
    greys: ['#E8E4E4', '#AFACAC', '#F6F4F4', '#959090', '#FBFAFB'],
    transparent: 'transparent',
  },
  transitions: {
    primary: '333ms ease-in-out',
  },
  textStyles: {
    bold: {
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 'bold',
    },
    normal: {
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: 'normal',
    },
  },
}
