import * as React from 'react'

import { Box } from 'src/ui/box'

export const TimeIcon = (): React.ReactElement => {
  return (
    <Box minWidth="24px" minHeight="24px">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <path
          d="M12 4.248a.955.955 0 00-.955.955v5.842H5.203a.955.955 0 100 1.91H12a.955.955 0 00.956-.955V5.203A.955.955 0 0012 4.248z"
          fill="#211515"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.993 5.375 18.624.007 12 0zM1.91 12C1.91 6.428 6.429 1.91 12 1.91S22.09 6.429 22.09 12c-.007 5.57-4.52 10.083-10.09 10.09-5.572 0-10.09-4.518-10.09-10.09z"
          fill="#211515"
        />
      </svg>
    </Box>
  )
}
