import React from 'react'

import { Box } from 'src/ui/box'

export const CookIcon = (): React.ReactElement => {
  return (
    <Box as="span">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <path
          d="M23.922 8.745a5.57 5.57 0 00-5.965-5.549A7.165 7.165 0 0012 0a7.165 7.165 0 00-5.957 3.196A5.57 5.57 0 00.078 8.745a5.571 5.571 0 004.77 5.506v7.69c0 1.135.924 2.059 2.06 2.059h10.185a2.061 2.061 0 002.059-2.06v-7.689a5.571 5.571 0 004.77-5.506zm-6.83 13.671H6.908a.482.482 0 01-.475-.475v-7.689a5.474 5.474 0 002.177-.804 7.12 7.12 0 006.781 0 5.476 5.476 0 002.178.804v7.689a.482.482 0 01-.475.475zm1.268-9.691a3.945 3.945 0 01-1.527-.304 7.126 7.126 0 002.058-3.352.792.792 0 00-1.527-.423A5.582 5.582 0 0112 12.725a5.582 5.582 0 01-5.364-4.08.792.792 0 10-1.527.424 7.126 7.126 0 002.058 3.352 3.983 3.983 0 01-5.505-3.676 3.984 3.984 0 014.657-3.921c.33.056.66-.1.824-.393A5.58 5.58 0 0112 1.584a5.58 5.58 0 014.857 2.847.792.792 0 00.824.393c.229-.04.457-.06.679-.06a3.984 3.984 0 013.978 3.981 3.983 3.983 0 01-3.978 3.98z"
          fill="#DA9C8C"
        />
      </svg>
    </Box>
  )
}
