import {
  space,
  color,
  border,
  layout,
  position,
  textStyle,
  colorStyle,
  typography,
  compose,
  ColorProps,
  SpaceProps,
  LayoutProps,
  BordersProps,
  PositionProps,
  TextStyleProps,
  TypographyProps,
  ColorStyleProps,
} from 'styled-system'
import styled from 'styled-components'

import { appTheme } from 'src/theme/theme'

export type TextProps = ColorProps &
  SpaceProps &
  LayoutProps &
  BordersProps &
  PositionProps &
  TextStyleProps &
  TypographyProps &
  ColorStyleProps & {
    as?: keyof JSX.IntrinsicElements
    children?: React.ReactNode | React.ReactNodeArray
    textIndent?: string
  }

export const Text = styled.p<TextProps>`
  margin: 0;
  box-sizing: border-box;
  cursor: inherit;

  text-indent: ${(p) => p.textIndent || 'unset'};

  ${compose(
    color,
    space,
    layout,
    border,
    position,
    textStyle,
    colorStyle,
    typography
  )};
`

Text.defaultProps = {
  as: 'p',
  color: 'black',
  fontFamily: appTheme.fonts.OpenSans,
}

Text.displayName = 'Text'
