import React, { ReactElement } from 'react'
import { Map, Placemark } from 'react-yandex-maps'

import { Content } from 'src/ui/content'
import { Common } from 'src/common/common'

import { routes } from 'src/routes'
import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { BreadCrumbs } from 'src/ui/bread-crumbs'

import { Address } from './ui/address'
import { Contacts } from './ui/contacts'
import { Schedule } from './ui/schedule'

export const ContactsPage = (): ReactElement => {
  return (
    <Common>
      <Content
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <BreadCrumbs
          crumbs={[
            { name: 'Главная', path: routes.root },
            { name: 'Контакты' },
          ]}
        />

        <Text
          as="h1"
          my="30px"
          fontSize={7}
          fontWeight="bold"
          lineHeight="44px"
        >
          Контакты
        </Text>

        <Box
          mb={70}
          width={1}
          display="flex"
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
        >
          <Box mr={5} width={[1, 1, 1, '370px']}>
            <Schedule />
            <Contacts />
            <Address />
          </Box>

          <Map
            width="100%"
            height="604px"
            defaultState={{ center: [53.62627, 55.937545], zoom: 16 }}
          >
            <Placemark geometry={[53.62627, 55.937545]} />
          </Map>
        </Box>
      </Content>
    </Common>
  )
}
