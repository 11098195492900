import {
  color,
  layout,
  position,
  space,
  border,
  borders,
  textStyle,
  colorStyle,
  typography,
  shadow,
  compose,
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  BorderProps,
  BordersProps,
  PositionProps,
  TextStyleProps,
  TypographyProps,
  ShadowProps,
  ColorStyleProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'

import { appTheme } from 'src/theme/theme'
import { InputTypes } from 'src/enums/input-types.enum'

export type InputProps = ColorProps &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  BorderProps &
  BordersProps &
  PositionProps &
  TextStyleProps &
  TypographyProps &
  ShadowProps &
  ColorStyleProps & {
    name?: string
    type?: InputTypes
    placeholder?: string
    placeholderColor?: string
    children?: never
    ref?: React.RefObject<HTMLInputElement>
  }

export const Input = styled.input<InputProps>`
  padding: 0;
  border: none;
  outline: none;
  appearance: none;
  box-sizing: border-box;

  ::placeholder {
    color: ${(p) =>
      p.placeholderColor
        ? themeGet(p.placeholderColor)
        : themeGet('colors.black')};
  }

  ${compose(
    color,
    space,
    layout,
    border,
    shadow,
    borders,
    position,
    textStyle,
    colorStyle,
    typography
  )}
`

Input.defaultProps = {
  fontFamily: appTheme.fonts.OpenSans,
}

Input.displayName = 'Input'
