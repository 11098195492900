import React, { useRef, ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'

import { useOutsideClick } from 'src/hooks/use-outside-click'

import { SearchMenuItem } from './search-menu-item'

interface Props {
  list: Product[]
  onHide: () => void
}

export const SearchMenu = ({ list, onHide }: Props): ReactElement<Props> => {
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>

  useOutsideClick(ref, onHide)

  return (
    <Box
      ref={ref}
      bg="white"
      mt={7}
      top={0}
      width={1}
      minHeight={8}
      maxHeight={11}
      position="absolute"
      boxShadow="0 5px 7px rgba(0, 0, 0, 0.09)"
      overflowY="auto"
    >
      <Box>
        {list.length > 0 ? (
          list.map((item, index) => (
            <SearchMenuItem
              key={index}
              item={item}
              isLast={index === list.length - 1}
              onHide={onHide}
            />
          ))
        ) : (
          <Box
            p={2}
            mx={3}
            height={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text as="span" color="black" textStyle="normal" fontSize={1}>
              Ничего не найдено
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
