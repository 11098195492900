import React, { ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import {
  space,
  color,
  layout,
  position,
  textStyle,
  colorStyle,
  typography,
  compose,
  ColorProps,
  SpaceProps,
  LayoutProps,
  PositionProps,
  TextStyleProps,
  TypographyProps,
  ColorStyleProps,
} from 'styled-system'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

type AnchorBaseProps = LinkProps &
  ColorProps &
  SpaceProps &
  LayoutProps &
  PositionProps &
  TextStyleProps &
  TypographyProps &
  ColorStyleProps

const AnchorBase = styled(Link)<AnchorBaseProps>`
  cursor: pointer;
  text-decoration: none;
  transition: opacity ${themeGet('transitions.primary')};

  @media (hover: hover) {
    :hover {
      opacity: 0.8;
    }
  }

  ${compose(color, space, layout, position, textStyle, colorStyle, typography)};
`

type AnchorProps = AnchorBaseProps & {
  children?: ReactNode
}

export const Anchor = ({
  children,
  ...rest
}: AnchorProps): React.ReactElement<AnchorProps> => {
  return <AnchorBase {...(rest as AnchorBaseProps)}>{children}</AnchorBase>
}
