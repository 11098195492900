import React from 'react'
import ReactDOM from 'react-dom'

import { App } from 'src/app'
import { api } from 'src/services/api'
import { initFontsAsync } from 'src/core/init-fonts'
import * as serviceWorker from 'src/serviceWorker'

api.init()
initFontsAsync('Open+Sans', ['400', '600', '700'])

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
