import React, { ReactElement } from 'react'
import uniqueId from 'lodash/uniqueId'
import styled from 'styled-components'
// @ts-ignore
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import { Box } from 'src/ui/box'
import { Image, ImageProps } from 'src/ui/image'
import { BreadCrumbs } from 'src/ui/bread-crumbs'

import { Content } from 'src/ui/content'
import { Common } from 'src/common/common'

import { routes } from 'src/routes'
import { appConfig } from 'src/config/app.config'

import { Text } from 'src/ui/text'

const StyledImage = styled(Image)<ImageProps>`
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      opacity: 0.8;
    }
  }
`

export const LicensesPage = (): ReactElement => {
  return (
    <Common>
      <SimpleReactLightbox>
        <Content
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <BreadCrumbs
            crumbs={[
              { name: 'Главная', path: routes.root },
              { name: 'Лицензии и сертификаты' },
            ]}
          />

          <Text
            as="h1"
            my="30px"
            fontSize={7}
            fontWeight="bold"
            lineHeight="44px"
          >
            Лицензии и сертификаты
          </Text>

          <SRLWrapper>
            <Box
              mb={7}
              width={1}
              display="grid"
              gridGap={['2em', '2em', '2em', '2em', '2em']}
              gridTemplateColumns={[
                'repeat(2, 48%)',
                'repeat(2, 48%)',
                'repeat(2, 48%)',
                'repeat(3, 31%)',
                'repeat(4, 23%)',
              ]}
            >
              {appConfig.licensesUrls.map((url) => (
                <StyledImage
                  key={uniqueId('image')}
                  src={url}
                  width="100%"
                  height="auto"
                  cursor="pointer"
                />
              ))}
            </Box>
          </SRLWrapper>
        </Content>
      </SimpleReactLightbox>
    </Common>
  )
}
