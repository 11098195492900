import React, { ReactElement } from 'react'
import { Router as ReactRouter, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { routes } from 'src/routes'
import { useCatalog } from 'src/hooks/use-catalog'
import { MainPage } from 'src/main/main.page'
import { AboutPage } from 'src/about/about.page'
import { ContactsPage } from 'src/contacts/contacts.page'
import { DeliveryPage } from 'src/delivery/delivery.page'
import { LicensesPage } from 'src/licenses/licenses.page'
import { NotFoundPage } from 'src/ui/not-found.page'
import { CatalogRouter } from 'src/catalog/catalog.router'

const history = createBrowserHistory()

export const Router = (): ReactElement => {
  useCatalog()

  return (
    <ReactRouter history={history}>
      <Switch>
        <Route exact path={routes.root} component={MainPage} />

        <Route path={routes.catalog} component={CatalogRouter} />

        <Route path={routes.delivery} component={DeliveryPage} />

        <Route path={routes.licenses} component={LicensesPage} />

        <Route path={routes.contacts} component={ContactsPage} />

        <Route path={routes.about} component={AboutPage} />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </ReactRouter>
  )
}
