import React, { ReactElement } from 'react'

import { menu } from 'src/menu'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Link } from 'src/ui/link'
import { Anchor } from 'src/ui/anchor'
import { Content } from 'src/ui/content'

export const Footer = (): ReactElement => {
  const [head, tail] = [menu.slice(0, 3), menu.slice(3)]

  return (
    <Content bg="greys.2" gridArea="footer" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        {head.map((item, index) => (
          <Anchor
            key={index}
            to={item.route || '/'} // FIXME:
            mb={index !== head.length - 1 ? '10px' : 0}
            color="black"
            fontSize={2}
            textStyle="bold"
            lineHeight="22px"
          >
            {item.title}
          </Anchor>
        ))}
      </Box>

      <Box display="flex" flexDirection="column">
        {tail.map((item, index) => (
          <Anchor
            key={index}
            to={item.route || '/'} // FIXME:
            mb={index !== tail.length - 1 ? '10px' : 0}
            color="black"
            fontSize={2}
            textStyle="bold"
            lineHeight="22px"
          >
            {item.title}
          </Anchor>
        ))}
      </Box>

      <Box display="flex" flexDirection="column">
        <Link
          href="tel:83473206369"
          mb="9px"
          color="primary"
          textStyle="bold"
          fontSize={5}
          lineHeight="30px"
        >
          8 (3473) 20-63-69
        </Link>

        <Link
          href="mailto:ipkarimovavenera@mail.ru"
          mb="6px"
          color="black"
          textStyle="bold"
          fontSize={2}
          lineHeight="22px"
        >
          ipkarimovavenera@mail.ru
        </Link>

        <Text color="black" textStyle="normal" fontSize={3} lineHeight="28px">
          Пн-Вс 9:00-20:00
        </Text>
      </Box>
    </Content>
  )
}
