import React, { useMemo, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { Badge } from 'src/ui/badge'
import { Image } from 'src/ui/image'

import { routes } from 'src/routes'
import { CardTypes } from 'src/enums/card-types.enum'
import { ProductTypeEnum } from 'src/enums/product-type.enum'

import { CardContainer } from './card-container'

interface Props {
  type: CardTypes
  product: Product
  isVisibleBadges?: boolean
  hiddenProductTypes?: ProductTypeEnum[]
}

export const Card = ({
  type,
  product,
  isVisibleBadges = true,
  hiddenProductTypes = [],
}: Props): ReactElement<Props> | null => {
  const {
    id,
    title,
    description,
    photos,
    weight,
    type: productType = [],
  } = product

  const history = useHistory()

  const price = useMemo(() => {
    const formatter = new Intl.NumberFormat('ru-RU', {
      currency: 'RUB',
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return formatter.format(product.price)
  }, [product.price])

  switch (type) {
    case CardTypes.Vertical:
      return (
        <CardContainer
          p="20px"
          bg="white"
          display="flex"
          alignItems="stretch"
          flexDirection="column"
          width={1}
          minHeight="372px"
          border={1}
          borderColor="greys.0"
          cursor="pointer"
          onClick={() => history.push(`${routes.catalog}/${id}`, { product })}
        >
          {isVisibleBadges && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              minHeight="25px"
            >
              {productType.map((type) =>
                !hiddenProductTypes.includes(type) ? (
                  <Badge key={type} type={type} />
                ) : null
              )}
            </Box>
          )}

          <Box height="230px" minHeight="230px" maxHeight="230px">
            {photos[0] && <Image src={photos[0]} width={1} height={1} />}
          </Box>

          <Box
            mt={1}
            flex="1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Text as="span" mb={2} fontSize={3} textStyle="bold" color="black">
              {title}
            </Text>

            <Text
              mt={0}
              mb="auto"
              fontSize={1}
              color="greys.3"
              lineHeight="copy"
              textStyle="normal"
              textAlign="left"
            >
              {description}
            </Text>

            <Box
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                as="span"
                ml="auto"
                fontSize={1}
                color="greys.3"
                textStyle="normal"
              >
                {weight}
              </Text>

              <Text
                ml={2}
                as="span"
                fontSize={2}
                color="black"
                textStyle="bold"
              >
                {price}
              </Text>
            </Box>
          </Box>
        </CardContainer>
      )

    case CardTypes.Horizontal:
      return (
        <CardContainer
          p="20px"
          bg="white"
          display="flex"
          alignItems="stretch"
          width={1}
          border={1}
          borderColor="greys.0"
          cursor="pointer"
          onClick={() => history.push(`${routes.catalog}/${id}`, { product })}
        >
          <Box
            mr={[1, 2, 3, '30px']}
            width="150px"
            height="100%"
            minWidth="150px"
            maxWidth="150px"
            minHeight="150px"
            maxHeight="150px"
          >
            {photos[0] && <Image src={photos[0]} width={1} height={1} />}
          </Box>

          <Box
            width={1}
            textAlign="right"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Text
              fontSize={3}
              textStyle="bold"
              color="black"
              textAlign="left"
              overflow="hidden"
            >
              {title}
            </Text>

            <Text
              mt="10px"
              mb="auto"
              fontSize={1}
              color="greys.3"
              lineHeight="copy"
              textStyle="normal"
              textAlign="left"
              overflow="hidden"
            >
              {description}
            </Text>

            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                as="span"
                ml="auto"
                fontSize={1}
                color="greys.3"
                textStyle="normal"
                overflow="hidden"
              >
                {weight}
              </Text>

              <Text
                ml={2}
                as="span"
                fontSize={2}
                color="black"
                textStyle="bold"
                overflow="hidden"
              >
                {price}
              </Text>
            </Box>
          </Box>
        </CardContainer>
      )

    default:
      return null
  }
}
