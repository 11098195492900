import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from 'src/ui/box'

const ImageBase = styled(Box)<BoxProps>`
  object-fit: contain;
  cursor: inherit;
`

export type ImageProps = BoxProps & {
  src?: string
  alt?: string
  children?: never
}

export const Image = ({
  src,
  alt,
  ...rest
}: ImageProps): ReactElement<ImageProps> => (
  <ImageBase as="img" src={src} alt={alt} {...rest} />
)
