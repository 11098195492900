import * as React from 'react'

export const ArrowDown = (): React.ReactElement => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.128 5.872a.927.927 0 00-1.31 0L8 9.689 4.183 5.872a.927.927 0 10-1.311 1.31l4.468 4.469.004.005a.925.925 0 001.312 0l.004-.005 4.468-4.468a.927.927 0 000-1.311z"
        fill="#211515"
      />
    </svg>
  )
}
