import React, { ReactElement } from 'react'

import { Content } from 'src/ui/content'

import { Logo } from './logo'
import { Search } from './search'
import { Contacts } from './contacts'

export const Header = (): ReactElement => {
  return (
    <Content bg="white" as="header" gridArea="header" zIndex={3}>
      <Logo />
      <Search />
      <Contacts />
    </Content>
  )
}
