import React from 'react'

import { Box } from 'src/ui/box'

export const SearchIcon = (): React.ReactElement => {
  return (
    <Box>
      <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
        <path
          d="M19.878 18.7l-5.81-5.81a7.876 7.876 0 001.765-4.973C15.833 3.55 12.282 0 7.917 0 3.55 0 0 3.551 0 7.917c0 4.365 3.551 7.916 7.917 7.916a7.876 7.876 0 004.973-1.765l5.81 5.81a.417.417 0 00.589 0l.589-.59a.417.417 0 000-.588zM7.917 14.167a6.257 6.257 0 01-6.25-6.25 6.257 6.257 0 016.25-6.25 6.257 6.257 0 016.25 6.25 6.257 6.257 0 01-6.25 6.25z"
          fill="#AFACAC"
        />
      </svg>
    </Box>
  )
}
