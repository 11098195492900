import React, { ReactElement } from 'react'

import { Content } from 'src/ui/content'
import { Common } from 'src/common/common'

import { Text } from 'src/ui/text'
import { BreadCrumbs } from 'src/ui/bread-crumbs'

import { routes } from 'src/routes'

export const DeliveryPage = (): ReactElement => {
  return (
    <Common>
      <Content
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <BreadCrumbs
          crumbs={[
            { name: 'Главная', path: routes.root },
            { name: 'Доставка' },
          ]}
        />

        <Text
          as="h1"
          my="30px"
          fontSize={7}
          fontWeight="bold"
          lineHeight="44px"
        >
          Доставка
        </Text>

        <Text
          display="inline-block"
          width={[1, 1, 2 / 3, 2 / 3]}
          fontSize={3}
          fontWeight="normal"
          lineHeight="28px"
        >
          Наша компания возьмет на&nbsp;себя все хлопоты по&nbsp;доставке
          кондитерских и&nbsp;хлебобулочных изделий, заказанных у&nbsp;нас. Вам
          нужно лишь позвонить и&nbsp;сообщить адрес доставки.
        </Text>

        <Text
          mt={4}
          display="inline-block"
          width={[1, 1, 2 / 3, 2 / 3]}
          fontSize={3}
          fontWeight="normal"
          lineHeight="28px"
        >
          Имеется собственный автопарк и&nbsp;высокопрофессиональные водители.
          А&nbsp;оптимальная схема логистики позволяет осуществлять доставку
          продукции вовремя.
        </Text>

        <Text
          mt={4}
          display="inline-block"
          width={[1, 1, 2 / 3, 2 / 3]}
          fontSize={3}
          fontWeight="normal"
          lineHeight="28px"
        >
          Точное время доставки согласовывается с&nbsp;менеджером при оформлении
          заказа.
        </Text>
      </Content>
    </Common>
  )
}
