import * as React from 'react'

export const Bakery = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M14.581 5.59H9.42C4.225 5.59 0 9.815 0 15.008a3.406 3.406 0 003.402 3.402h17.196A3.406 3.406 0 0024 15.008c0-5.193-4.225-9.418-9.419-9.418zm6.017 11.414H3.402c-1.1 0-1.996-.895-1.996-1.996 0-3.542 2.31-6.554 5.504-7.61-.276.607-.468 1.358-.571 2.25a12.62 12.62 0 00-.073 1.948.703.703 0 001.404-.078c-.045-.84.03-3.224.92-4.162.232-.246.495-.36.829-.36h1.51c-.39.663-.652 1.55-.779 2.652a12.613 12.613 0 00-.073 1.948.703.703 0 001.404-.079c-.046-.837.027-3.218.919-4.16.232-.246.496-.36.83-.36h1.351l.157.001c-.35.596-.596 1.375-.735 2.332a12.615 12.615 0 00-.114 2.266.703.703 0 101.404-.079c-.05-.898.05-3.558 1.093-4.316 3.552.82 6.208 4.01 6.208 7.807 0 1.1-.896 1.996-1.996 1.996z"
        fill="#B28B86"
      />
    </svg>
  )
}
