import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { Anchor } from 'src/ui/anchor'
import { CookieIcon } from 'src/ui/icons/cookie'
import { routes } from 'src/routes'

export const Logo = (): ReactElement => {
  return (
    <Box display="flex" alignItems="center" cursor="pointer">
      <CookieIcon />

      <Anchor
        to={routes.root}
        ml="10px"
        color="primary"
        textStyle="bold"
        fontSize={5}
      >
        ИП Каримова В.З.
      </Anchor>
    </Box>
  )
}
