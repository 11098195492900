import * as React from 'react'

import { Box } from 'src/ui/box'

export const CallIcon = (): React.ReactElement => {
  return (
    <Box minWidth="24px" minHeight="24px">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#prefix__clip0)">
          <path
            d="M7.171 9.318a1.738 1.738 0 010 2.453l-.919.918a12.252 12.252 0 005.059 5.06s.022.01 0 0l.918-.92.07.07-.07-.07a1.737 1.737 0 012.445-.008l.008.008 2.208 2.21c.328.327.508.762.508 1.225 0 .462-.18.899-.508 1.226l-1.767 1.767-.006.005c-.498.495-1.186.738-2.011.738h-.019c-.773-.003-1.671-.22-2.656-.64l-.022-.01c-1.84-.794-3.86-2.239-5.69-4.07l-.021-.02C2.87 17.427 1.429 15.409.641 13.569l-.01-.024c-.865-2.03-.853-3.703.112-4.668L2.51 7.11a1.735 1.735 0 012.444-.008l.009.008L7.17 9.318zm8.595 11.043a.14.14 0 00-.002-.197l-2.209-2.209-.002-.002a.14.14 0 00-.097-.038h-.003a.136.136 0 00-.097.04l-1.722 1.723-.508-.241a13.828 13.828 0 01-6.563-6.563l-.24-.508 1.722-1.722a.142.142 0 000-.199L3.836 8.236l-.003-.003a.142.142 0 00-.196.003L1.87 10.004c-.21.209-.31.577-.273 1.09.036.508.204 1.14.509 1.847l-.092.04.092-.04c.71 1.658 2.037 3.51 3.74 5.214 1.702 1.701 3.555 3.03 5.212 3.74h.001-.001c.71.304 1.34.473 1.849.508.512.036.88-.063 1.09-.272l1.767-1.768.002-.002zM24 6.396c-.006 1.72-.783 3.329-2.185 4.53-1.374 1.178-3.193 1.826-5.12 1.826h-.016a8.41 8.41 0 01-1.906-.222s-2.653.984-4.114 1.105l-.283.024.207-.194c.64-.6 1.212-1.272 1.705-2.002a7.4 7.4 0 01-.714-.537c-1.407-1.206-2.185-2.822-2.185-4.55v-.021c.007-1.72.784-3.328 2.185-4.53C12.949.649 14.768 0 16.695 0h.023c1.918.005 3.728.652 5.097 1.826l-.064.075.064-.075C23.223 3.032 24 4.647 24 6.376v.02zM10.983 6.39c.01 2.615 2.551 4.767 5.712 4.767h.019c3.158-.009 5.693-2.166 5.693-4.782 0-2.622-2.545-4.783-5.712-4.783h-.019c-3.157.009-5.693 2.167-5.693 4.783v.015zm6.741-.008a1.03 1.03 0 01-1.029 1.021h-.007a1.028 1.028 0 01-1.022-1.028v-.007a1.03 1.03 0 011.029-1.023h.007a1.03 1.03 0 011.022 1.03v.007zm-3.254 0a1.03 1.03 0 01-1.03 1.021h-.007a1.028 1.028 0 01-1.021-1.028v-.007a1.028 1.028 0 011.028-1.023h.007a1.03 1.03 0 011.023 1.03v.007zm6.508 0a1.03 1.03 0 01-1.03 1.021h-.005a1.028 1.028 0 01-1.023-1.028v-.007a1.029 1.029 0 011.029-1.023h.007a1.03 1.03 0 011.022 1.03v.007z"
            fill="#211515"
          />
        </g>
        <defs>
          <clipPath id="prefix__clip0">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}
