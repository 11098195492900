import * as React from 'react'

interface IconRootProps {}

export const ArrowRight = (
  _: IconRootProps
): React.ReactElement<IconRootProps> => {
  return (
    <svg width={14} height={24} viewBox="0 0 14 24" fill="none">
      <path
        d="M.452 23.564c.603.581 1.58.581 2.183 0l10.913-10.513a1.449 1.449 0 000-2.102L2.635.435a1.585 1.585 0 00-2.183 0 1.449 1.449 0 000 2.103L10.274 12 .452 21.462a1.448 1.448 0 000 2.102z"
        fill="#da9c8c"
      />
    </svg>
  )
}
