import React from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { ShopIcon } from 'src/ui/icons/shop'

export const Address = (): React.ReactElement => {
  return (
    <Box p={4} mb={5} display="flex" border={1} borderColor="greys.0">
      <ShopIcon />

      <Box ml={4}>
        <Text
          pb={3}
          color="black"
          fontSize={4}
          lineHeight="30px"
          fontWeight="bold"
        >
          Адрес
        </Text>

        <Text color="black" fontSize={3} lineHeight="28px" fontWeight="normal">
          Республика Башкортостан, г.&nbsp;Стерлитамак, ул.&nbsp;Худайбердина,
          85/1, склад&nbsp;45
        </Text>
      </Box>
    </Box>
  )
}
