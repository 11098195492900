import React, { ReactElement } from 'react'

import { Box } from 'src/ui/box'
import { catalog } from 'src/catalog'

import { MenuItem } from './menu-item'

export const Menu = (): ReactElement => {
  return (
    <Box bg="greys.4" width={1} height="432px" gridArea="menu">
      {catalog.map(({ route, title, icon }, index) => (
        <MenuItem key={index} to={route} icon={icon} text={title} />
      ))}
    </Box>
  )
}
