import React, { ReactElement } from 'react'

import { Content } from 'src/ui/content'
import { Common } from 'src/common/common'

import { Text } from 'src/ui/text'
import { BreadCrumbs } from 'src/ui/bread-crumbs'

import { routes } from 'src/routes'

export const AboutPage = (): ReactElement => {
  return (
    <Common>
      <Content
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <BreadCrumbs
          crumbs={[
            { name: 'Главная', path: routes.root },
            { name: 'О компании' },
          ]}
        />

        <Text
          as="h1"
          my="30px"
          fontSize={7}
          fontWeight="bold"
          lineHeight="44px"
        >
          О компании
        </Text>

        <Text
          display="inline-block"
          width={[1, 1, 2 / 3, 2 / 3]}
          fontSize={3}
          fontWeight="normal"
          lineHeight="28px"
        >
          Дорогие гости, приобретая нашу продукцию, Вы&nbsp;можете быть уверены,
          в&nbsp;том что получите вкусные и&nbsp;качественные изделия.
        </Text>

        <Text
          mt={4}
          display="inline-block"
          width={[1, 1, 2 / 3, 2 / 3]}
          fontSize={3}
          fontWeight="normal"
          lineHeight="28px"
        >
          Производство начало свою деятельность с&nbsp;2010&nbsp;года.
          За&nbsp;прошедшее время сформировалась команда профессионалов, знающих
          и&nbsp;любящих свое дело; установились основные принципы нашего дела;
          появились заслуженная репутация и&nbsp;доверие клиентов. Компания
          тщательно выбирает поставщиков сырья и&nbsp;использует
          в&nbsp;производстве только качественные и&nbsp;свежие продукты.
          Сбалансированный вкус кондитерских изделий&nbsp;&mdash; заслуга
          совместной работы кондитеров и&nbsp;технологов. На&nbsp;производстве
          действует контроль качества на&nbsp;каждом этапе, гарантирующий
          безопасность готовой продукции. Является участником проекта
          &laquo;Продукт Башкортостана&raquo;.
        </Text>
      </Content>
    </Common>
  )
}
