import React from 'react'
import { YMaps } from 'react-yandex-maps'
import { ThemeProvider } from 'styled-components'

import { Router } from 'src/router'
import { GlobalStateProvider } from 'src/core/global-state'
import { appTheme, GlobalStyle } from 'src/theme/theme'

export const App = () => (
  <ThemeProvider theme={appTheme}>
    <GlobalStateProvider>
      <YMaps>
        <Router />
        <GlobalStyle />
      </YMaps>
    </GlobalStateProvider>
  </ThemeProvider>
)
