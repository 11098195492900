import React from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { CallIcon } from 'src/ui/icons/call'

export const Contacts = (): React.ReactElement => {
  return (
    <Box p={4} mb={5} display="flex" border={1} borderColor="greys.0">
      <CallIcon />

      <Box ml={4}>
        <Text
          pb={3}
          color="black"
          fontSize={4}
          lineHeight="30px"
          fontWeight="bold"
        >
          Для связи
        </Text>

        <Text color="black" fontSize={3} lineHeight="28px" fontWeight="normal">
          8 (3473) 20-63-69
        </Text>

        <Text color="black" fontSize={3} lineHeight="28px" fontWeight="normal">
          +7 987 055-50-40
        </Text>

        <Text color="black" fontSize={3} lineHeight="28px" fontWeight="normal">
          ipkarimovavenera@mail.ru
        </Text>
      </Box>
    </Box>
  )
}
