import React from 'react'

import { Box } from 'src/ui/box'
import { Text } from 'src/ui/text'
import { ProductTabsEnum } from 'src/enums/product-tabs.enum'

interface Props {
  data: Product
  activeTab: ProductTabsEnum
}

const productEnergyKeysMap: { [key: string]: string } = {
  protein: 'Белки',
  fat: 'Жиры',
  carbo: 'Углеводы',
  caloric: 'Калорийность',
}

export const TabsContent = ({
  activeTab,
  data,
}: Props): React.ReactElement<Props> | null => {
  switch (activeTab) {
    case ProductTabsEnum.Composition:
      return (
        <Box>
          <Text fontSize="14px" lineHeight="24px">
            {data.composition || ''}
          </Text>
        </Box>
      )

    case ProductTabsEnum.StorageConditions:
      return (
        <Box>
          <Text fontSize="14px" lineHeight="24px">
            {data.storage || ''}
          </Text>

          <Text fontSize="14px" lineHeight="24px">
            {data.expiration || ''}
          </Text>
        </Box>
      )

    case ProductTabsEnum.EnergyContent:
      return (
        <Box display="flex">
          {Object.entries(productEnergyKeysMap).map(([key, value]) => (
            <Box key={key} mr="54px" textAlign="center">
              <Text
                color="secondary"
                fontSize="32px"
                fontWeight="bold"
                lineHeight="44px"
              >
                {data?.energy?.[key] || 0}
              </Text>

              <Text fontSize="14px" fontWeight="600" lineHeight="24px">
                {value}
              </Text>
            </Box>
          ))}
        </Box>
      )

    default:
      return null
  }
}
