// for more info see https://github.com/zeit/next.js/issues/512#issuecomment-322026199

import FontFaceObserver from 'fontfaceobserver'

export const initFontsAsync = async (
  family: string,
  weights: string[]
): Promise<void> => {
  try {
    const link = document.createElement('link')

    link.rel = 'stylesheet'

    link.href = `https://fonts.googleapis.com/css?family=${family}:${weights.join(
      ','
    )}`

    document.head.append(link)

    const font = new FontFaceObserver(family)

    await font.load()

    document.documentElement.classList.add(family.toLowerCase())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
