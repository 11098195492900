import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'

import { firebaseConfig } from 'src/config/firebase.config'

export interface QueryCondition {
  field: string
  op: firebase.firestore.WhereFilterOp
  value: any
}

export const api = {
  init: () => firebase.initializeApp(firebaseConfig),

  isInitialized: () => !!firebase.apps.length,

  fetch: (
    collection: string,
    condition?: QueryCondition,
    order?: string,
    limit?: number
  ): Promise<firebase.firestore.DocumentData[]> =>
    new Promise((resolve, reject) => {
      const query = firebase.firestore().collection(collection)

      if (limit) {
        query.limit(limit)
      }

      if (order) {
        query.orderBy(order)
      }

      // TODO: refactor this!
      if (condition) {
        const { field, op, value } = condition

        query
          .where(field, op, value)
          .get()
          .then((snapshot) => {
            const results: firebase.firestore.DocumentData[] = []

            if (!snapshot.empty) {
              snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                  results.push({ ...doc.data(), id: doc.id })
                }
              })
            }

            resolve(results)
          })
          .catch(reject)
      } else {
        query
          .get()
          .then((snapshot) => {
            const results: firebase.firestore.DocumentData[] = []

            if (!snapshot.empty) {
              snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                  results.push({ ...doc.data(), id: doc.id })
                }
              })
            }

            resolve(results)
          })
          .catch(reject)
      }
    }),

  fetchUrl: (url: string): Promise<string> =>
    url
      ? firebase.storage().refFromURL(url).getDownloadURL()
      : Promise.resolve(),
}
