import React, { ReactNode, ReactElement } from 'react'
import {
  GridProps,
  SpaceProps,
  LayoutProps,
  DisplayProps,
  FlexboxProps,
  PositionProps,
  BackgroundColorProps,
  BorderProps,
  OverflowProps,
} from 'styled-system'

import { Box } from 'src/ui/box'

export type ContentProps = GridProps &
  SpaceProps &
  LayoutProps &
  BorderProps &
  DisplayProps &
  FlexboxProps &
  OverflowProps &
  PositionProps &
  BackgroundColorProps & {
    children: ReactNode
    as?: keyof JSX.IntrinsicElements
  }

export const Content = ({
  as,
  children,
  ...rest
}: ContentProps): ReactElement<ContentProps> => {
  return (
    <Box
      as={as}
      px={[5, 5, 5, 7, '128px']}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      {children}
    </Box>
  )
}
