import React, { useMemo, ReactElement } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { catalogRoutes } from 'src/routes'
import { NotFoundPage } from 'src/ui/not-found.page'
import { CatalogPage } from 'src/catalog/catalog.page'
import { ProductPage } from 'src/product/product.page'

export const CatalogRouter = (): ReactElement => {
  const { path } = useRouteMatch()

  const routes = useMemo(
    () => Object.values(catalogRoutes).map((route) => `${path}${route}`),
    [path]
  )

  return (
    <Switch>
      <Route exact path={[path, ...routes]} component={CatalogPage} />

      <Route exact path={`${path}/:id`} component={ProductPage} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
