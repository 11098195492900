import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, BoxProps } from 'src/ui/box'
import { Anchor } from 'src/ui/anchor'

import { appTheme } from 'src/theme/theme'

interface Props {
  to: string
  text: string
  icon: React.FunctionComponent
}

const Root = styled(Box)<BoxProps & { isActive: boolean }>`
  height: 48px;

  a {
    color: ${(p) => appTheme.colors[p.isActive ? 'primary' : 'black']};
  }

  svg {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  @media (hover: hover) {
    :hover {
      a {
        color: ${themeGet('colors.primary')};
      }

      svg > path {
        fill: ${themeGet('colors.primary')};
      }
    }
  }
`

export const CatalogItem = ({ to, text, icon }: Props): ReactElement<Props> => {
  const location = useLocation()

  return (
    <Root
      display="flex"
      alignItems="center"
      isActive={location.pathname === to}
    >
      <Anchor to={to} fontSize={2} textStyle="normal">
        {React.createElement(icon)}
        {text}
      </Anchor>
    </Root>
  )
}
