import * as React from 'react'

import { Box } from 'src/ui/box'

export const ShopIcon = (): React.ReactElement => {
  return (
    <Box minWidth="24px" minHeight="24px">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <path
          d="M23.209 6.121L21.602 1.3a.804.804 0 00-.763-.55H3.161a.804.804 0 00-.763.55L.791 6.12a.819.819 0 00-.041.232V7.18a4.001 4.001 0 001.607 3.196V20.84a2.41 2.41 0 002.41 2.411h14.465a2.41 2.41 0 002.41-2.41V10.392v-.018A4 4 0 0023.25 7.18v-.804-.022a.819.819 0 00-.041-.232zm-5.584-3.764h2.635l1.072 3.214h-3.707V2.357zm-4.821 0h3.214v3.214h-3.214V2.357zm-4.822 0h3.214v3.214H7.982V2.357zm-4.242 0h2.635v3.214H2.668L3.74 2.357zm9.867 19.286h-3.214v-6.429h3.214v6.429zm6.429-.804c0 .444-.36.804-.804.804h-4.018V14.41a.804.804 0 00-.803-.804H9.589a.804.804 0 00-.803.804v7.232H4.768a.804.804 0 01-.804-.804v-9.723a3.972 3.972 0 003.215-.738 4.072 4.072 0 004.821 0 4.072 4.072 0 004.821 0c.914.7 2.087.97 3.215.738v9.723zm-.804-11.25a2.375 2.375 0 01-1.805-.834.804.804 0 00-1.21 0 2.424 2.424 0 01-3.612 0 .831.831 0 00-1.21 0 2.424 2.424 0 01-3.611 0 .83.83 0 00-1.21 0c-.451.529-1.111.834-1.806.834a2.41 2.41 0 01-2.41-2.41h19.285a2.41 2.41 0 01-2.41 2.41z"
          fill="#211515"
        />
      </svg>
    </Box>
  )
}
