import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from 'src/ui/box'
import { Text } from 'src/ui/text'

interface Props {
  text: string
  isActive: boolean
  onClick: () => void
}

const Root = styled(Box)<BoxProps & { isActive: boolean }>`
  @media (hover: hover) {
    :hover {
      opacity: ${(p) => (!p.isActive ? 0.6 : 1)};
    }
  }
`

export const Button = ({
  text,
  isActive,
  onClick,
}: Props): ReactElement<Props> => {
  return (
    <Root
      py={2}
      px={3}
      mr={2}
      mb={3}
      bg={isActive ? 'secondary' : 'white'}
      height="32px"
      display="flex"
      cursor="pointer"
      border="1px solid"
      alignItems="center"
      borderColor={isActive ? 'secondary' : 'greys.0'}
      isActive={isActive}
      onClick={onClick}
    >
      <Text
        fontSize={1}
        textStyle="normal"
        fontWeight={isActive ? 2 : 0}
        lineHeight="24px"
        as="span"
      >
        {text}
      </Text>
    </Root>
  )
}
