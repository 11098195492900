import React, { useMemo, ReactElement } from 'react'

import { Box } from 'src/ui/box'

import { Common } from 'src/common/common'
import { useGlobalState } from 'src/hooks/use-global-state'
import { ProductTypeEnum } from 'src/enums/product-type.enum'

import { Hits } from './ui/hits'
import { NewGoods } from './ui/new-goods'
import { Proposals } from './ui/proposals'

export const MainPage = (): ReactElement => {
  const { state } = useGlobalState()

  const [news, proposals, hits] = useMemo(
    () =>
      state.catalog
        ? state.catalog.reduce(
            (acc: [Product[], Product[], Product[]], item) => {
              if (item.type.includes(ProductTypeEnum.New)) {
                acc[0].push(item)
              }

              if (item.type.includes(ProductTypeEnum.Proposal)) {
                acc[1].push(item)
              }

              if (item.type.includes(ProductTypeEnum.Hit)) {
                acc[2].push(item)
              }

              return acc
            },
            [[], [], []]
          )
        : [[], [], []],
    [state.catalog]
  )

  return (
    <Common>
      <Box
        display="grid"
        gridGap={0}
        gridArea="content"
        gridTemplateRows={[
          '540px 580px 1fr',
          '540px 580px 1fr',
          '540px 412px 1fr',
          '540px 412px 1fr',
        ]}
        gridTemplateColumns="1fr"
        gridTemplateAreas="'newGoods' 'proposals' 'hits'"
      >
        <NewGoods data={news} />
        <Proposals data={proposals} />
        <Hits data={hits} />
      </Box>
    </Common>
  )
}
