export const ProductCategoryEnum = Object.freeze({
  PuffCookies: 'PUFF_COOKIES' as 'PUFF_COOKIES',
  ButterCookies: 'BUTTER_COOKIES' as 'BUTTER_COOKIES',
  Shortbread: 'SHORTBREAD' as 'SHORTBREAD',
  Pastries: 'PASTRIES' as 'PASTRIES',
  Cupcakes: 'CUPCAKES' as 'CUPCAKES',
  CustardBiscuits: 'CUSTARD_BISCUITS',
  Pies: 'PIES' as 'PIES',
  Bakery: 'BAKERY' as 'BAKERY',
})

export type ProductCategoryEnum = EnumLiteralsOf<typeof ProductCategoryEnum>
