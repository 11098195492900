import React, { ReactElement, useState } from 'react'

import { Box } from 'src/ui/box'
import { Spinner } from 'src/ui/spinner'
import { useGlobalState } from 'src/hooks/use-global-state'
import { useScrollToTop } from 'src/hooks/use-scroll-to-top'

import { Menu } from './ui/menu'
import { Header } from './ui/header'
import { Footer } from './ui/footer'
import { Catalog } from './ui/catalog'
import { Advantages } from './ui/advantages'

interface Props {
  children: React.ReactNode
}

export const Common = ({ children }: Props): ReactElement<Props> => {
  useScrollToTop()

  const { state } = useGlobalState()

  const [isOpenCatalog, setIsOpenCatalog] = useState(false)

  const toggleCatalog = () => setIsOpenCatalog((prevState) => !prevState)

  return (
    <Box
      m="0 auto"
      display="grid"
      minWidth="768px"
      maxWidth="1440px"
      gridGap={0}
      gridTemplateRows={[
        '80px 60px minmax(800px, 1fr) 212px',
        '80px 60px minmax(800px, 1fr) 212px',
        '80px 60px minmax(800px, 1fr) 212px',
        '80px 60px minmax(800px, 1fr) 212px',
        '40px 80px 60px minmax(800px, 1fr) 212px',
      ]}
      gridTemplateColumns={['100%', '100%', '100%', '1fr', '1fr']}
      gridTemplateAreas={[
        "'header' 'menu' 'content' 'footer'",
        "'header' 'menu' 'content' 'footer'",
        "'header' 'menu' 'content' 'footer'",
        "'header' 'menu' 'content' 'footer'",
        "'advantages' 'header' 'menu' 'content' 'footer'",
      ]}
    >
      <Header />

      <Advantages />

      <Menu isOpenCatalog={isOpenCatalog} toggleCatalog={toggleCatalog} />

      <Catalog isOpen={isOpenCatalog} />

      {state.isLoading ? <Spinner /> : children}

      <Footer />
    </Box>
  )
}
