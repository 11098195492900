import * as React from 'react'

export const Bucket = () => {
  return (
    <svg width={24} height={20} viewBox="0 0 24 20" fill="none">
      <path
        d="M20.735 8.932l1.14-1.14a4.458 4.458 0 000-6.297 4.458 4.458 0 00-6.297 0l-3.68 3.68a5.862 5.862 0 00-5.054-2.899 5.866 5.866 0 00-5.805 6.656H0v7.16l.533.132c1.234.307 2.479.564 3.733.771v2.813h15.468v-2.813a47.466 47.466 0 003.733-.77L24 16.09V8.932h-3.265zM16.572 2.49a3.05 3.05 0 014.309 0 3.05 3.05 0 010 4.309l-2.135 2.134h-1.96a3.219 3.219 0 00-.886-1.534 3.218 3.218 0 00-2.25-.911v1.406a1.82 1.82 0 011.65 1.04h-2.652a5.842 5.842 0 00-.158-2.362l4.082-4.082zM6.844 3.683a4.458 4.458 0 014.381 5.25H2.463a4.458 4.458 0 014.381-5.25zM18.328 18.4H5.672v-1.195a47.484 47.484 0 0012.656 0V18.4zm4.266-3.412A46.106 46.106 0 0112 16.224c-3.564 0-7.125-.415-10.594-1.235v-4.65h21.188v4.65z"
        fill="#B28B86"
      />
    </svg>
  )
}
