import * as React from 'react'

export const CustardBiscuit = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M18.778 6.025H5.222A5.228 5.228 0 000 11.247v3.012a3.72 3.72 0 003.716 3.716h16.568A3.72 3.72 0 0024 14.259v-3.012a5.228 5.228 0 00-5.222-5.222zM5.222 7.431h13.556a3.822 3.822 0 013.75 3.113h-.737c-1.219 0-2.21.99-2.21 2.21a.804.804 0 01-1.606 0 2.21 2.21 0 00-2.21-2.21H1.472a3.822 3.822 0 013.75-3.113zm17.372 6.828a2.312 2.312 0 01-2.31 2.31H3.716a2.312 2.312 0 01-2.31-2.31V11.95h14.36c.439 0 .803.354.803.803 0 .283.054.554.152.803H3.716a.703.703 0 000 1.407h16.568a.703.703 0 00.471-1.225c.149-.297.233-.631.233-.985 0-.443.36-.803.803-.803h.803v2.31z"
          fill="#B28B86"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
