import React from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from 'src/ui/box'

const StyledButton = styled(Box)<BoxProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;

  @media (hover: hover) {
    :hover {
      opacity: 0.5;
    }
  }
`

StyledButton.displayName = 'StyledButton'

interface Props {
  onClick: (event: React.MouseEvent | React.TouchEvent) => void
  icon: React.FunctionComponent
}

export const Button = ({ icon, onClick }: Props): React.ReactElement => {
  const Icon = icon

  return (
    <StyledButton onClick={onClick}>
      <Icon />
    </StyledButton>
  )
}
