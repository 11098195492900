import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { useHistory } from 'react-router-dom'

import { Image } from 'src/ui/image'
import { Box, BoxProps } from 'src/ui/box'
import { Text, TextProps } from 'src/ui/text'

import { routes } from 'src/routes'

interface Props {
  item: Product
  isLast: boolean
  onHide: () => void
}

const Root = styled(Box)<BoxProps>`
  @media (hover: hover) {
    :hover {
      opacity: 0.5;

      & > span {
        color: ${themeGet('colors.primary')};
      }
    }
  }
`

const Title = styled(Text)<TextProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SearchMenuItem = ({
  item,
  isLast,
  onHide,
}: Props): ReactElement<Props> => {
  const { title } = item

  const history = useHistory()

  const goToDetails = () => {
    history.push(`${routes.catalog}/${item.id}`, { product: item })
    onHide()
  }

  return (
    <Root
      p={2}
      mx={3}
      height={8}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      borderBottom={isLast ? 'none' : '1px solid #E8E4E4'}
      cursor="pointer"
      onClick={goToDetails}
    >
      <Box mr={3} width={7} height={7}>
        {item?.photos?.[0] && (
          <Image mr="30px" src={item?.photos?.[0]} width={1} height={1} />
        )}
      </Box>

      <Title as="span" color="black" textStyle="normal" fontSize={1}>
        {title}
      </Title>
    </Root>
  )
}
